/* eslint-disable max-lines */
import tagService from "./../../../common/template/services/TagService";

const yelpToEegeo = {
  "app_tag_to_yelp_leaf_categories": {
    "accommodation": ["accommodation", "venues", "resorts", "vacation_rentals"],
    "airport": ["airlines", "airports"],
    "art": ["art_museums", "museums", "culturalcenter", "theater", "arts"],
    "bank": ["banks", "financialservices", "paydayloans", "financialadvising", "insurance", "investing", "taxservices"],
    "bed_breakfast": ["bedbreakfast"],
    "beer": ["beer_and_wine", "pubs", "beerbar", "beergardens", "beertours"],
    "burgers": ["burgers", "hotdogs"],
    "bus": ["publictransport","buses","airport_shuttles"],
    "business": ["business", "accountants", "advertising", "businessconsulting", "employmentagencies", "graphicdesign", "lawyers", "estateplanning", "legalservices", "processservers", "lifecoach", "marketing", "officecleaning", "personalassistants", "productdesign", "publicrelations", "security", "softwaredevelopment", "translationservices", "videofilmproductions", "web_design", "professional"],
    "camping": ["summer_camps", "campgrounds"],
    "casino": ["casinos"],
    "childcare": ["childcare"],
    "chinese_thai": ["chinese", "cantonese", "dimsum", "szechuan", "thai"],
    "christianity":["churches"],
    "cinema":["movietheaters"],
    "cocktail":["bars", "nightlife", "cocktailbars"],
    "coffee":["coffee", "cafes", "cafeteria"],
    "cycling":["bikes", "cyclingclasses"],
    "dentist":["dentist", "pediatric_dentists", "orthodontists", "generaldentistry"],
    "donuts":["donuts"],
    "education":["education","educationservices","elementaryschools","highschools","preschools","specialed"],
    "entertainment": ["entertainment", "arcades", "gardens", "culturalcenter", "jazzandblues", "opera", "theater", "planetarium", "arts"],
    "events": ["eventservices", "eventplanning", "festivals" ],
    "farm": ["farms"],
    "fashion": ["fashion"],
    "fashion_kids": ["childcloth"],
    "fashion_men": ["menscloth"],
    "fashion_women": ["womenscloth"],
    "ferry":["boatcharters"],
    "first_aid": ["firstaidclasses"],
    "fishing":["fishing"],
    "florist":["florists"],
    "fuel":["servicestations","oilchange"],
    "food_drink": ["food_drink", "bbq", "breakfast_brunch", "comfortfood", "delis", "diners", "dinnertheater", "hotdogs", "food_court", "foodstands", "gastropubs", "gluten_free", "hotpot", "salad", "sandwiches", "soulfood", "soup", "southern", "supperclubs", "tapas", "restaurants", "food"],
    "football":["football"],
    "gallery":["galleries"],
    "general": ["localservices", "amenities", "buildingsupplies", "carpenters", "carpeting", "contractors", "electricians", "flooring", "gardeners", "handyman", "homecleaning", "interiordesign", "isps", "landscaping", "lighting", "masonry_concrete", "movers", "painters", "plumbing", "poolservice", "realestate", "apartments", "propertymgmt", "realestateagents", "roofing", "securitysystems", "structuralengineers", "tiling", "treeservices", "utilities", "homeservices"],
    "golf":["golf", "mini_golf"],
    "groceries":["grocery"],
    "health": ["health", "chiropractors", "physicians", "cardiology", "earnosethroat", "emergencymedicine", "familydr", "hospitalists", "surgeons", "emergencyrooms", "walkinclinics", "physicaltherapy"],
    "historic_monument":["landmarks"],
    "homeware":["homeandgarden"],
    "hospital":["hospitals", "medcenters", "hospice"],
    "hostel":["hostels"],
    "hotel":["hotels"],
    "hvac_unit":["hvac"],
    "indian":["indpak"],
    "islam":["mosques"],
    "judaism":["synagogues"],
    "lake":["lakes"],
    "library":["libraries"],
    "limo":["limos"],
    "mobile_phone":["mobilephones", "mobilephonerepair"],
    "music":["musicvenues", "musicvideo", "media", "musicalinstrumentsandteachers", "vinyl_records"],
    "observatory":["observatories"],
    "park":["dog_parks","skate_parks","playgrounds","recreation","parks"],
    "parking":["parking"],
    "pharmacy":["drugstores"],
    "pizza":["pizza"],
    "place_of_worship":["religiousorgs", "buddhist_temples", "hindu_temples"],
    "police":["policedepartments"],
    "post_office":["postoffices"],
    "race_track":["gokarts"],
    "sailing":["boating"],
    "seafood":["seafoodmarkets", "seafood"],
    "shooting":["gun_ranges"],
    "shopping": ["shopping", "antiques", "artsandcrafts", "costumes", "bookstores", "comicbooks", "mags", "videogamestores", "videoandgames", "cosmetics", "deptstores", "discountstore", "dutyfreeshops", "electronics", "accessories", "deptstores", "shoes", "sportswear", "giftshops", "hobbyshops", "appliances", "furniture", "hardware", "homedecor", "kitchenandbath", "mattresses", "gardening", "outdoorfurniture", "knittingsupplies", "outlet_stores", "pawn", "shoppingcenters", "souvenirs", "sportgoods", "sportswear", "tabletopgames", "thrift_stores", "wholesale_stores"],
    "skiing":["skiresorts"],
    "solar_panels":["solarinstallation"],
    "spa":["spas","medicalspa","beautysvc","healthretreats"],
    "sports": ["sports_leisure", "amusementparks", "aquariums", "archery", "badminton", "baseballfields", "basketballcourts", "battingcages", "bowling", "diving", "freediving", "gyms", "martialarts", "pilates", "qigong", "yoga", "flyboarding", "gymnastics", "horseracing", "leisure_centers", "playgrounds", "recreation", "sports_clubs", "surfing", "swimmingpools", "tennis", "trampoline", "waterparks", "active"],
    "stationery":["officeequipment","stationery"],
    "steak":["steak"],
    "sushi":["sushi"],
    "taxi":["taxis"],
    "technology":["itservices", "computers"],
    "tourist_info": ["tourism", "architecturaltours", "arttours", "bustours", "foodtours", "historicaltours", "walkingtours", "winetours", "tours"],
    "town_hall":["publicservicesgovt"],
    "transport": ["transport", "cablecars", "trains"],
    "university":["university_housing", "collegeuniv"],
    "vegan":["vegan"],
    "vegetarian":["vegetarian"],
    "wine":["wine_bars", "wineries"],
    "zoo":["zoos"]
  },

  "app_tag_to_yelp_root_category": {
    "accommodation": "hotels",
    "airport": "airports",
    "art": "museums",
    "bank": "banks",
    "bed_breakfast": "bedbreakfast",
    "beer": "pubs",
    "burgers": "burgers",
    "bus": "buses",
    "business": "professional",
    "camping": "campgrounds",
    "casino": "casinos",
    "childcare": "childcare",
    "chinese_thai": "chinese",
    "christianity": "churches",
    "cinema": "movietheaters",
    "cocktail": "cocktailbars",
    "coffee": "coffee",
    "cycling": "bikes",
    "dentist": "dentists",
    "donuts": "donuts",
    "education": "education",
    "entertainment": "arts",
    "farm": "farms",
    "fashion": "fashion",
    "fashion_kids":"childcloth",
    "fashion_men":"menscloth",
    "fashion_women":"womenscloth",
    "ferry": "boatcharters",
    "fishing": "fishing",
    "florist": "florists",
    "food_drink": "food",
    "football": "football",
    "forest": "parks",
    "fuel": "servicestations",
    "gallery": "galleries",
    "general": "localservices",
    "golf": "golf",
    "groceries": "grocery",
    "health": "health",
    "historic_monument": "landmarks",
    "homeware":"homeandgarden",
    "hospital": "hospitals",
    "hostel": "hostels",
    "hotel": "hotels",
    "hvac_unit": "hvac",
    "indian": "indpak",
    "islam": "mosques",
    "judaism": "synagogues",
    "lake":"lakes",
    "library": "libraries",
    "limo": "limos",
    "mobile_phone":"mobilephones",
    "music": "musicvenues",
    "observatory":"observatories",
    "park": "parks",
    "parking": "parking",
    "pharmacy": "drugstores",
    "pizza": "pizza",
    "post_office": "postoffices",
    "race_track": "gokarts",
    "sailing": "boating",
    "seafood":"seafood",
    "shooting": "gun_ranges",
    "shopping": "shopping",
    "skiing": "skiresorts",
    "sports": "active",
    "solar_panels": "solarinstallation",
    "spa": "spas",
    "stationery": "stationery",
    "steak": "steak",
    "sushi": "sushi",
    "taxi": "taxis",
    "technology": "computers",
    "tourist_info": "tours",
    "town_hall": "publicservicesgovt",
    "transport": "transport",
    "university": "collegeuniv",
    "vegan": "vegan",
    "vegetarian": "vegetarian",
    "wine": "wine_bars",
    "zoo": "zoos"
  },

  "default_app_tag" : "pin"
};

const getEegeoTagFromYelpCategory = (yelpTagToFind) => {
  let tag = yelpToEegeo.default_app_tag;
  Object.keys(yelpToEegeo.app_tag_to_yelp_leaf_categories).some(eegeoTag => {
    const tagFound = yelpToEegeo.app_tag_to_yelp_leaf_categories[eegeoTag].some(yelpTag => {
      return yelpTag === yelpTagToFind;
    });
    if (tagFound) {
      tag = eegeoTag;
    }
    return tagFound;
  });
  return tag;
};

export const getReadableYelpCategories = (yelpCategories) => {
  if (!yelpCategories) {
    return [];
  }
  return yelpCategories.map(category => { return category.title; });
};

export const getIconKeyFromYelpCategories = (yelpCategories) => {
  let iconKey = tagService.getDefaultIconKey();
  if (!yelpCategories) {
    return iconKey;
  }

  const eegeoTags = yelpCategories.map(category => (getEegeoTagFromYelpCategory(category.alias)));
  eegeoTags.some(eegeoTag => {
    if (eegeoTag !== yelpToEegeo.default_app_tag) {
      iconKey = tagService.getAllTags()[eegeoTag].iconKey;
      return true;
    }
    return false;
  });
  return iconKey;
};

export const getYelpCategoriesFromEegeoTag = (eegeoTag) => {
  if (eegeoTag in yelpToEegeo.app_tag_to_yelp_root_category) {
    return [yelpToEegeo.app_tag_to_yelp_root_category[eegeoTag]];
  }
  if (eegeoTag in yelpToEegeo.app_tag_to_yelp_leaf_categories) {
    return yelpToEegeo.app_tag_to_yelp_leaf_categories[eegeoTag];
  }
  return null;
};