import React from "react";

import AutocompleteOptionsGroup from "../components/AutocompleteOptionsGroup.jsx";

export default class AutocompleteOptionsContainer extends React.Component {

  constructor(props) {
    super(props);

    let numOptionGroups = 0;
    if (Object.keys(props.options.places).length > 0) numOptionGroups++;
    if (Object.keys(props.options.locations).length > 0) numOptionGroups++;
    if (Object.keys(props.options.tags).length > 0) numOptionGroups++;

    this.state = {
      numOptionGroups: numOptionGroups
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let numOptionGroups = 0;
    if (Object.keys(nextProps.options.places).length > 0) numOptionGroups++;
    if (Object.keys(nextProps.options.locations).length > 0) numOptionGroups++;
    if (Object.keys(nextProps.options.tags).length > 0) numOptionGroups++;
    this.setState({ numOptionGroups: numOptionGroups });
  }

  _getPoiAutocompleteOptionsGroup() {
    const { options, onPoiOptionSelect } = this.props;
    if (options.places.length === 0) return null;
    return <AutocompleteOptionsGroup options={options.places}
      onOptionSelect={onPoiOptionSelect}
      numOtherOptionGroups={this.state.numOptionGroups - 1}/>;
  }

  _getLocationAutocompleteOptionsGroup() {
    const { options, onLocationOptionSelect } = this.props;
    if (options.locations.length === 0) return null;
    return <AutocompleteOptionsGroup options={options.locations}
      onOptionSelect={onLocationOptionSelect}
      numOtherOptionGroups={this.state.numOptionGroups - 1}/>;
  }

  _getTagAutocompleteOptionsGroup() {
    const { options, onTagOptionSelect } = this.props;
    if (options.tags.length === 0) return null;
    return <AutocompleteOptionsGroup options={options.tags}
      onOptionSelect={onTagOptionSelect}
      numOtherOptionGroups={this.state.numOptionGroups - 1}/>;
  }

  render() {
    return (
      <div>
        {this._getPoiAutocompleteOptionsGroup()}
        {this._getLocationAutocompleteOptionsGroup()}
        {this._getTagAutocompleteOptionsGroup()}
      </div>
    );
  }
}