const COUNTRY_CODES = [ "GB", "US", "CA", "IE", "IT", "NO", "SE", "FI", "SJ" ];

const getSubtitle = (result) => {
  let subtitleArray = [];
  if (result.adminName3 !== undefined) {
    subtitleArray.push(result.adminName3);
  }
  if (result.adminName2 !== undefined) {
    subtitleArray.push(result.adminName2);
  }
  if (result.adminName1 !== undefined) {
    subtitleArray.push(result.adminName1);
  }
  if (result.countryName !== undefined) {
    subtitleArray.push(result.countryName);
  }
  return subtitleArray.join(", ");
};

export default class GeoNamesLocationService {

  constructor() {
    this._initialized = false;
    this._username = null;
    this._token = null;
    this._hasToken = false;
    this._urlRoot = "https://secure.geonames.org/";
    this._urlRootPremium = "https://secure.geonames.net/";
    this._restrictByCountry = true;
    this._countryCodes = COUNTRY_CODES;
  }

  initialize(username, token, restrictByCountry = true, countryCodes = COUNTRY_CODES) {
    if (username) {
      this._username = username;
      this._initialized = true;
      if(token) {
        this._token = token;
        this._hasToken = true;
      }
    }
    else {
      this._initialized = false;
      this._username = null;
    }

    if (restrictByCountry === undefined) {
      this._restrictByCountry = true;
    }
    else {
      this._restrictByCountry = restrictByCountry;
    }
    this._countryCodes = countryCodes || COUNTRY_CODES;
  }

  isInitialized() {
    return this._initialized;
  }

  fetchAutocompleteResults(term) {
    const maxResults = 10;
    let urlString;

    if(this._hasToken) {
      urlString = this._urlRootPremium + "searchJSON?username=" + this._username + "&q=" + encodeURIComponent(term) + "&maxRows=" + maxResults
            + "&name_startsWith=" + encodeURIComponent(term) + "&token=" + encodeURIComponent(this._token);
    }
    else {
      urlString = this._urlRoot + "searchJSON?username=" + this._username + "&q=" + encodeURIComponent(term) + "&maxRows=" + maxResults
            + "&name_startsWith=" + encodeURIComponent(term);
    }
    return this._formatResponse(fetch(urlString));
  }

  fetchNearbyByTerm(latLng, term, maxResults) {
    let urlString;

    if(this._hasToken){
      urlString = this._urlRootPremium + "searchJSON?username=" + this._username + "&q=" + encodeURIComponent(term) + "&maxRows=" + maxResults
            + "&token=" + encodeURIComponent(this._token);
    }
    else{
      urlString = this._urlRoot + "searchJSON?username=" + this._username + "&q=" + encodeURIComponent(term) + "&maxRows=" + maxResults;
    }
    return this._formatResponse(fetch(urlString));
  }

  _formatResponse(fetchResponse) {
    return new Promise((resolve, reject) => {
      fetchResponse
        .then(response => response.json())
        .then(result => {
          if(this._restrictByCountry) {
            let index = result.geonames.length - 1;
            while (index >= 0) {
              if (this._countryCodes.indexOf(result.geonames[index].countryCode) === -1) {
                result.geonames.splice(index, 1);
              }
              index -= 1;
            }
          }

          resolve(result.geonames.map(geoname => ({
            name: geoname.name,
            subtitle: getSubtitle(geoname),
            latLng: L.latLng(geoname.lat, geoname.lng)
          })));
        })
        .catch(error => {
          reject(error);
        });
    });
  }
}
