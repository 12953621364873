import React from "react";

export default class TextField extends React.Component {

  constructor(props) {
    super(props);

    this.focus = this.focus.bind(this);
  }

  _getSpinnerIcon() {
    const { onClearClick } = this.props;
    return this.props.shouldShowSpinner ? <div className="spinner-icon" onClick={onClearClick}/> : null;
  }

  focus() {
    this._textField.focus();
  }

  getInputText() {
    this._textField.value;
  }

  render() {
    const { value, placeholder, onChange, onFocus, onBlur, onKeyDown, onClearClick, shouldShowSpinner } = this.props;

    const clearIconClassName = "clear-icon" + (shouldShowSpinner || value === "" ? " fade" : "");

    return (
      <div className="text-field-container">
        <input
          className="text-field"
          type="text"
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          onKeyDown={onKeyDown}
          ref={(node) => { this._textField = node; }}
        />
        {this._getSpinnerIcon()}
        <button className={clearIconClassName} onClick={onClearClick}></button>
      </div>
    );
  }
}