export default class LocationSearchServiceFacade {

  constructor(internalSearchService) {
    this.initialize(internalSearchService);
  }

  initialize(internalSearchService) {
    this._internalSearchService = internalSearchService;
  }

  isEnabled() {
    if (this._internalSearchService && typeof this._internalSearchService.isEnabled === "function") {
      return this._internalSearchService.isEnabled();
    }
    return true;
  }

  fetchNearbyLocationsByTerm(latLng, term, callback) {
    if (this._internalSearchService && typeof this._internalSearchService.fetchNearbyLocationsByTerm === "function") {
      this._internalSearchService.fetchNearbyLocationsByTerm(latLng, term, callback);
    }
  }

  fetchAutocompleteOptions(latLng, term, callback) {
    if (this._internalSearchService && typeof this._internalSearchService.fetchAutocompleteOptions === "function") {
      this._internalSearchService.fetchAutocompleteOptions(latLng, term, callback);
    }
  }

  implementsAutocomplete() {
    return (this._internalSearchService && typeof this._internalSearchService.fetchAutocompleteOptions === "function");
  }
}
