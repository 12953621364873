import React from "react";

import tagService from "../../../../../common/template/services/TagService";

export default class MenuOption extends React.Component {

  constructor(props) {
    super(props);

    this._showingState = {
      collapsed: 0,
      expanding: 1,
      expanded: 2,
      collapsing: 3
    };

    this.state = {
      showingState: (props.shouldBeExpanded ? this._showingState.expanded : this._showingState.collapsed)
    };

    this._onOptionSelected = this._onOptionSelected.bind(this);
    this._setShowingStateExpanded = this._setShowingStateExpanded.bind(this);
    this._setShowingStateCollapsed = this._setShowingStateCollapsed.bind(this);
    this._setShowingStateExpanding = this._setShowingStateExpanding.bind(this);
    this._setShowingStateCollapsing = this._setShowingStateCollapsing.bind(this);
    this._hasChildItems = this._hasChildItems.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { showingState } = this.state;
    if (nextProps.shouldBeExpanded) {
      if (showingState === this._showingState.collapsed || showingState === this._showingState.collapsing) {
        this._setShowingStateExpanding();
      }
    }
    else if (showingState === this._showingState.expanded || showingState === this._showingState.expanding) {
      this._setShowingStateCollapsing();
    }
  }

  _setShowingStateExpanded() {
    this.setState({ showingState: this._showingState.expanded });
  }

  _setShowingStateExpanding() {
    if (this.toggleButtonNode) {
      this.toggleButtonNode.addEventListener("transitionend", this._setShowingStateExpanded);
      this.toggleButtonNode.removeEventListener("transitionend", this._setShowingStateCollapsed);
    }
    this.setState({ showingState: this._showingState.expanding });
  }

  _setShowingStateCollapsed() {
    this.setState({ showingState: this._showingState.collapsed });
    this.props.onCollapsedCallback();
  }

  _setShowingStateCollapsing() {
    if (this.toggleButtonNode) {
      this.toggleButtonNode.removeEventListener("transitionend", this._setShowingStateExpanded);
      this.toggleButtonNode.addEventListener("transitionend", this._setShowingStateCollapsed);
    }
    this.setState({ showingState: this._showingState.collapsing });
  }

  _onOptionSelected() {
    const { text, context, callback } = this.props;
    this._callCallbackIfPresent(text, context, callback);
  }

  _callCallbackIfPresent(text, context, callback) {
    if (typeof callback === "function") {
      callback(text, context);
    }
  }

  _getOptionListContainer() {
    const { shouldBeExpanded, childItems } = this.props;
    if (!this._hasChildItems(childItems))
    {
      return null;
    }

    const { showingState } = this.state;
    if (!shouldBeExpanded && showingState === this._showingState.collapsed) {
      this.optionListContainerNode = null;
      return null;
    }

    let rowClassName = "option-row";
    if (showingState === this._showingState.expanding) {
      rowClassName += " row-expand";
    }
    else if (showingState === this._showingState.collapsing) {
      rowClassName += " row-collapse";
    }

    const childItemRows = childItems.map((childItem, index) => {
      const iconUrl = tagService.getIconUrlForKey(childItem.icon);
      const iconStyle = {
        backgroundImage: "url(" + iconUrl + ")"
      };
      return (
        <div className={rowClassName}
          key={index}
          onClick={() => {
            this._callCallbackIfPresent(childItem.text, childItem.context, childItem.callback);
          }}>
          <div className="icon"
            style={iconStyle}/>
          <div className="text">
            {childItem.text}
          </div>
        </div>
      );
    });

    return (
      <div className="option-list-container">
        {childItemRows}
      </div>);
  }

  _hasChildItems(childItems) {
    return Array.isArray(childItems) && childItems.length > 0;
  }

  render() {
    const { text, childItems, shouldBeExpanded, optionIndex, onExpandCollapseToggle} = this.props;

    const toggleButtonClassName = "toggle-button" + (shouldBeExpanded ? " expanded" : " collapsed");

    let textDiv = null;
    if ((typeof text === "string") && text !== "") {
      textDiv = (
        <div className="title-text">
          {text}
        </div>
      );
    }

    let buttonDiv = null;
    if (this._hasChildItems(childItems)) {
      buttonDiv = (
        <button className={toggleButtonClassName} ref={(node) => { this.toggleButtonNode = node; }}/>
      );
    }

    return (
      <div className="menu-option-group">
        <div className="title-container"
          onClick={() => {
            if (this._hasChildItems(childItems))
            {
              onExpandCollapseToggle(optionIndex);
            }
            this._onOptionSelected();
          }}>
          {buttonDiv}
          {textDiv}
        </div>
        {this._getOptionListContainer()}
      </div>
    );
  }
}
