const MIN_TAG_SEARCH_SCORE = 0.25;
const MIN_SEARCH_SCORE = 0.4;

export default class EegeoPoiService {

  constructor() {
    this._initialized = false;
    this._apiKey = null;
    this._urlRoot = null;
    this._credentialsMode = "same-origin";
    this._minSearchScore = MIN_SEARCH_SCORE;
  }

  initialize(apiKey, searchUrl, samlConfiguration, minSearchScore = MIN_SEARCH_SCORE ) {
    if (apiKey && searchUrl) {
      this._apiKey = apiKey;
      this._urlRoot = searchUrl;
      this._initialized = true;
    }
    else {
      this._initialized = false;
      this._apiKey = null;
      this._urlRoot = null;
    }
    this._useSamlAuthentication = samlConfiguration ? samlConfiguration.useSamlAuthentication : false;
    this._samlRedirectCallback = samlConfiguration ? samlConfiguration.samlRedirectCallback : null;
    this._minSearchScore = minSearchScore || MIN_SEARCH_SCORE;
  }

  isInitialized() {
    return this._initialized;
  }

  fetchAllNearby(latLng, radius, maxResults, indoorInfo = null) {

    let endpoint = null;
    if (indoorInfo) {
      endpoint = "indoor?i=" + indoorInfo.id + "&f=" + indoorInfo.floor + "&";

      if (indoorInfo.currentFloorOnly) {
        endpoint += "s=0&";
      }
    }
    else {
      endpoint = "tag?" + "ms=" + MIN_TAG_SEARCH_SCORE + "&";
    }

    const urlString = this._urlRoot + endpoint + "lat=" + latLng.lat + "&lon=" + latLng.lng + "&r=" + radius + "&n=" + maxResults + "&apikey=" + this._apiKey;
    return this._doFetch(urlString);
  }

  fetchNearbyByTag(latLng, tags, radius, maxResults, indoorInfo = null) {
    const currentFloorOnly = (indoorInfo && indoorInfo.currentFloorOnly) ? "&s=0" : "";
    const searchType = indoorInfo ? "indoor?i=" + indoorInfo.id + "&f=" + indoorInfo.floor + currentFloorOnly + "&" : "tag?" + "ms=" + MIN_TAG_SEARCH_SCORE + "&";

    const urlString = this._urlRoot + searchType + "t=" + tags + "&lat=" + latLng.lat + "&lon=" + latLng.lng + "&r=" + radius + "&n=" + maxResults + "&apikey=" + this._apiKey;
    return this._doFetch(urlString);
  }

  fetchNearbyByTerm(latLng, term, radius, maxResults, indoorInfo = null, endpoint = "search") {
    const indoorParams = indoorInfo ? "&indoor_id=" + indoorInfo.id + "&f=" + indoorInfo.floor : "";
    const urlString = this._urlRoot + endpoint + "?s=" + encodeURIComponent(term) + "&lat=" + latLng.lat + "&lon="+ latLng.lng
                        + indoorParams + "&r=" + radius + "&n=" + maxResults + "&apikey=" + this._apiKey + "&ms=" + this._minSearchScore;

    return this._doFetch(urlString);
  }

  fetchAutocompleteResults(latLng, text, maxResults) {
    const urlString = this._urlRoot + "autocomplete?s=" + encodeURIComponent(text) + "&lat=" + latLng.lat + "&lon=" + latLng.lng + "&n=" + maxResults + "&apikey=" + this._apiKey;
    return this._doFetch(urlString);
  }

  _doFetch(url) {
    let fetchHeaders = {};
    if(this._useSamlAuthentication) {
      fetchHeaders = { "X-WRLD-TOOL": "true" };
    }
    return fetch(url, {
      credentials:  this._credentialsMode,
      headers: fetchHeaders
    }).then(response => {
      if(this._useSamlAuthentication) {
        if(response.status === 403) {
          return response.json().then(responseJson => {
            let redirectUrl = responseJson.auth_url;
            if(this._samlRedirectCallback) {
              this._samlRedirectCallback(redirectUrl);
            }
            else {
              window.location.assign(redirectUrl);
            }
          });
        }
      }
      return response;
    });
  }
}
