
import { searchTypes } from "./constants/SearchTypes";
import tagService from "./../../../common/template/services/TagService";
import SearchQuery from "./SearchQuery";

export default class SearchQueryBuilder {

  constructor() {
    this._outdoorSearchMenuItems = [];
  }

  init(config) {
    this._outdoorSearchMenuItems = config.get("outdoorSearchMenuItems");
  }

  buildTextSearchQuery(searchTerm, endpoints, options) {
    return new SearchQuery(searchTerm, searchTypes.TEXT, Object.assign({}, options, { endpoints }));
  }

  buildTagSearchQuery(searchTag, options) {
    const displayString = typeof options.name === "string" ? options.name : tagService.getHumanReadable(searchTag);
    const skipYelpSearch = this._shouldSkipYelpSearch(searchTag, options);
    return new SearchQuery(searchTag, searchTypes.TAG, Object.assign({}, options, {
      displayString, // string visible in searchbar when running the tag search
      skipYelpSearch
    }));
  }

  _shouldSkipYelpSearch(searchTag, options) {
    if (typeof options.skipYelpSearch === "boolean") {
      return options.skipYelpSearch;
    }
    return this._shouldTagSkipYelpSearch(searchTag);
  }

  // Skip yelp if also set to do so in corresponding 'Find' menu tag.
  _shouldTagSkipYelpSearch(searchTag) {
    if (!Array.isArray(this._outdoorSearchMenuItems)) {
      return false;
    }
    const shouldSkipYelp = this._outdoorSearchMenuItems.some((menuItem) => {
      return (menuItem.searchTag === searchTag) && (menuItem.skip_yelp_search === true);
    });
    return shouldSkipYelp;
  }
}