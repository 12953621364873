import React from "react";

import tagService from "./../../../../../common/template/services/TagService";

import MenuGroup from "../components/MenuGroup.jsx";

export default class MenuContainer extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      menuOpen: false,
      optionIndexExpanded: null,
      optionIndexToBeExpanded: null
    };

    this._onTagOptionSelected = this._onTagOptionSelected.bind(this);
    this._onLocationOptionSelected = this._onLocationOptionSelected.bind(this);
    this._toggleMenu = this._toggleMenu.bind(this);
    this._marshallHardCodedMenuOptionGroup = this._marshallHardCodedMenuOptionGroup.bind(this);
    this._onOptionCollapsed = this._onOptionCollapsed.bind(this);
    this._onExpandCollapseToggle = this._onExpandCollapseToggle.bind(this);
    this._fetchSearchMenuConfigForLocation = this._fetchSearchMenuConfigForLocation.bind(this);
  }

  _onExpandCollapseToggle(optionIndex) {
    const collapsingCurrentlyExpanded = this.state.optionIndexToBeExpanded === optionIndex;
    const optionIndexExpanded = this.state.optionIndexExpanded === null ? optionIndex : this.state.optionIndexExpanded;
    const optionIndexToBeExpanded = collapsingCurrentlyExpanded ? null : optionIndex;
    this.setState({
      optionIndexExpanded: optionIndexExpanded,
      optionIndexToBeExpanded: optionIndexToBeExpanded
    });
  }

  _onOptionCollapsed() {
    this.setState({ optionIndexExpanded: this.state.optionIndexToBeExpanded });
  }

  _onTagOptionSelected(optionText, optionContext) {
    const { closeMenu, onTagOptionSelected } = this.props;
    closeMenu();
    onTagOptionSelected(optionContext);
  }

  _onLocationOptionSelected(optionText, optionContext) {
    const { closeMenu, onLocationOptionSelected } = this.props;
    closeMenu();
    onLocationOptionSelected(optionContext);
  }

  _toggleMenu() {
    const { menuOpen, toggleMenu } = this.props;
    if (menuOpen) {
      this.setState({ optionIndexExpanded: this.state.optionIndexToBeExpanded });
    }
    toggleMenu();
  }

  _getMenu() {
    if (!this.props.menuOpen) return null;

    const searchbarContainerHeight = $("#eegeo-searchbar").parent().parent().height();
    const searchBarHeight = $("#eegeo-searchbar").outerHeight();
    const menuHeaderBreakHeight = 8;
    const menuBodyContainerStyle = {
      maxHeight: searchbarContainerHeight - searchBarHeight - menuHeaderBreakHeight
    };
    const menuTitle = this.props.config.get("menuTitle") || "WRLD Menu";

    return (
      <div className="eegeo-searchbar-menu-container">
        <div className="header-container">
          <div className="header-text">{menuTitle}</div>
        </div>
        <hr/>
        <div className="menu-body-container"
          style={menuBodyContainerStyle}>
          {this._getMenuGroups()}
        </div>
      </div>
    );
  }

  _getMenuGroups() {
    const { config } = this.props;
    const menuGroupConfigs = config.get("menuGroupConfigs");
    const locationJumpsConfig = config.get("locationJumps");
    const searchMenuItemsConfig = this._fetchSearchMenuConfigForLocation();

    let menuGroups = (locationJumpsConfig.length > 0 || searchMenuItemsConfig.length > 0) ?
      menuGroupConfigs.concat([this._marshallHardCodedMenuOptionGroup(locationJumpsConfig, searchMenuItemsConfig)]) :
      menuGroupConfigs;

    const { optionIndexExpanded, optionIndexToBeExpanded } = this.state;
    let optionIndex = 0;
    let menuGroupViews = menuGroups.map((group, index) => {
      let initialOptionIndexInGroup = optionIndex;
      optionIndex += group.options.length;
      return (
        <div key={index}>
          {index !== 0 ? (<hr/>) : null}
          <MenuGroup
            title={group.title}
            menuOptions={group.options}
            initialOptionIndexInGroup={initialOptionIndexInGroup}
            optionIndexExpanded={optionIndexExpanded}
            optionIndexToBeExpanded={optionIndexToBeExpanded}
            onExpandCollapseToggle={this._onExpandCollapseToggle}
            onCollapseCallback={this._onOptionCollapsed}
          />
        </div>
      );
    });

    return menuGroupViews;
  }

  _fetchSearchMenuConfigForLocation()
  {
    const { mapController, config} = this.props;

    let searchMenuConfig = [];
    if (mapController.isIndoors()) {
      if(!config.get("overrideIndoorSearchMenuItems"))
      {
        searchMenuConfig = mapController.getIndoorMapSearchTags();
        if (searchMenuConfig.length === 0) {
          searchMenuConfig = config.get("indoorSearchMenuItems");
        }
      }
    }
    if (searchMenuConfig.length === 0) {
      searchMenuConfig = config.get("outdoorSearchMenuItems");
    }

    return searchMenuConfig.map(tag => ({
      name: tag.name,
      searchTag: tag.searchTag === undefined ? tag.search_tag : tag.searchTag,
      iconKey: tag.iconKey === undefined ? tag.icon_key : tag.iconKey,
      skipPoiSearch: false,
      skipYelpSearch: tag.skip_yelp_search === undefined ? false : tag.skip_yelp_search,
      yelpMapping: tag.yelp_mapping === undefined ? null : tag.yelp_mapping
    }));
  }

  _marshallHardCodedMenuOptionGroup(locationJumpsConfig, searchMenuItemsConfig)
  {
    let hardCodedMenuGroup = [];

    if (searchMenuItemsConfig.length > 0) {
      let childItems = searchMenuItemsConfig.map((option) => {
        return {
          text: option.name,
          context: {
            searchTag: option.searchTag.toLowerCase(),
            name: tagService.getHumanReadable(option.searchTag),
            skipYelpSearch: option.skipYelpSearch === undefined ? false : option.skipYelpSearch,
            yelpMapping: null
          },
          icon: option.iconKey,
          callback: this._onTagOptionSelected
        };
      });

      hardCodedMenuGroup.push({
        "text": "Find",
        "childItems": childItems
      });
    }

    if (locationJumpsConfig.length > 0) {
      let titleText = "Locations";
      let childItems = locationJumpsConfig.filter((option) => {
        if (option.overrideTitle) {
          titleText = option.overrideTitle;
        }
        return option.name && option.latLng;
      })
        .map((option) => {
          return {
            text: option.name,
            context: {
              latLng: L.latLng(option.latLng),
              zoom: option.zoom,
              headingDegrees: option.headingDegrees
            },
            icon: option.iconKey || tagService.getDefaultIconKey(),
            callback: this._onLocationOptionSelected
          };
        });

      hardCodedMenuGroup.push({
        "text": titleText,
        "childItems": childItems
      });
    }

    return {options: hardCodedMenuGroup};
  }

  render() {
    const buttonClasses = ["eegeo-ripple-button"];
    if (this.props.menuOpen) buttonClasses.push("open");

    return (
      <div>
        {this._getMenu()}
        <div id="eegeo-searchbar-menu-button-container">
          <button className={buttonClasses.join(" ")} onClick={this._toggleMenu} />
        </div>
      </div>
    );
  }
}
