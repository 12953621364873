/* eslint-disable max-lines */
import React from "react";

import { SEARCH_RESULTS, AUTOCOMPLETE_RESULTS } from "../../constants/SearchBarConstants";

import { searchTypes } from "./../../../search/constants/SearchTypes";

import SearchBar from "../components/SearchBar.jsx";
import ResultsContainer from "./ResultsContainer.jsx";
import MenuContainer from "./MenuContainer.jsx";

export default class SearchBarContainer extends React.Component {

  constructor(props) {
    super(props);

    this._updateShowMenuButton(props.config);

    this.state = {
      contentVisible: true,
      animated: false,
      searchString: "",
      resultTypeShowing: null,
      menuOpen: false,
      suggestions: props.searchViewModel._autocompleteSuggestions,
      searchResults: props.searchViewModel._searchResults,
      isIndoors: false // This state is for triggering rerenders in React and should not be used for logic.
    };

    this.closeMenu = this.closeMenu.bind(this);
    this._toggleMenu = this._toggleMenu.bind(this);
    this._showAutocompleteResultsView = this._showAutocompleteResultsView.bind(this);
    this._hideResultsView = this._hideResultsView.bind(this);
    this._onPoiAutocompleteOptionSelected = this._onPoiAutocompleteOptionSelected.bind(this);
    this._onLocationOptionSelected = this._onLocationOptionSelected.bind(this);
    this._onSearchResultSelected = this._onSearchResultSelected.bind(this);
    this._onTagSelect = this._onTagSelect.bind(this);
    this._onLocationSelect = this._onLocationSelect.bind(this);
    this._onInputChange = this._onInputChange.bind(this);
    this._onClearClicked = this._onClearClicked.bind(this);
    this._processSearch = this._processSearch.bind(this);

    this._onAutocompleteSuggestionsCleared = this._onAutocompleteSuggestionsCleared.bind(this);
    this._onAutocompleteSuggestionsUpdated = this._onAutocompleteSuggestionsUpdated.bind(this);
    this._onSearchResultsCleared = this._onSearchResultsCleared.bind(this);
    this._onSearchResultsUpdated = this._onSearchResultsUpdated.bind(this);
    this._onSearch = this._onSearch.bind(this);

    props.mapController.registerIndoorsCallback("indoormapenter", () => { this.setState({ isIndoors: true }); });
    props.mapController.registerIndoorsCallback("indoormapexit", () => { this.setState({ isIndoors: false }); });

    this.props.searchViewModel.on("autocompletesuggestionscleared", this._onAutocompleteSuggestionsCleared);
    this.props.searchViewModel.on("autocompletesuggestionsupdated", this._onAutocompleteSuggestionsUpdated);
    this.props.searchViewModel.on("searchresultscleared", this._onSearchResultsCleared);
    this.props.searchViewModel.on("searchresultsupdated", this._onSearchResultsUpdated);
    this.props.searchViewModel.on("search", this._onSearch);
  }

  componentWillUnmount() {
    this.props.searchViewModel.off("autocompletesuggestionscleared", this._onAutocompleteSuggestionsCleared);
    this.props.searchViewModel.off("autocompletesuggestionsupdated", this._onAutocompleteSuggestionsUpdated);
    this.props.searchViewModel.off("searchresultscleared", this._onSearchResultsCleared);
    this.props.searchViewModel.off("searchresultsupdated", this._onSearchResultsUpdated);
    this.props.searchViewModel.off("search", this._onSearch);
  }

  _onAutocompleteSuggestionsCleared(event) {
    this.setState({ suggestions: event.suggestions });
    this._hideResultsView();
  }

  _onAutocompleteSuggestionsUpdated(event) {
    this.setState({ suggestions: event.suggestions });
    this._showAutocompleteResultsView();
  }

  _onSearchResultsCleared(event) {
    this.setState({ searchResults: event.searchResults });
    this._hideResultsView();
    this.props.onSearchResultsClearedCallback();
  }

  _onSearchResultsUpdated(event) {
    let combinedResults = Object.assign({}, event.searchResults.yelp);
    for (let endpointServicePath in event.searchResults.places) {
      combinedResults = Object.assign(combinedResults, event.searchResults.places[endpointServicePath]);
    }
    this.setState({ searchResults: event.searchResults });
    this._showSearchResultsView();
    this.props.onSearchResultsUpdatedCallback(combinedResults);
  }

  _onSearch({ searchQuery }) {
    this.setState({ searchString: searchQuery.getDisplayString() });
    this.props.onSearchCallback();
  }

  _updateShowMenuButton(config) {
    if (config.get("backButtonEnabled")) {
      this._showMenuButton = true;
      return;
    }

    this._showMenuButton = config.get("menuGroupConfigs").length > 0
                        || config.get("outdoorSearchMenuItems").length > 0
                        || config.get("locationJumps").length > 0;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { config } = nextProps;
    this._updateShowMenuButton(config);
    this.setState({poiResults: this._emptyPoiResults});
    if (!this._showMenuButton) {
      this.closeMenu();
    }
  }

  show(animated) {
    this.setState({
      contentVisible: true,
      animated: animated
    });
  }

  hide(animated) {
    this.closeMenu();
    this.clear();
    this.setState({
      contentVisible: false,
      animated: animated
    });
  }

  openMenu() {
    if (!this._showMenuButton || this.state.menuOpen) return;
    this.setState({ menuOpen: true });
    this.props.onMenuOpenCallback();
  }

  closeMenu() {
    if (!this.state.menuOpen) return;
    this.setState({ menuOpen: false });
    this.props.onMenuCloseCallback();
  }

  clear() {
    this.props.searchViewModel.clear();
    this._hideResultsView();
    this.setState({ searchString: "" });
  }

  focus() {
    this._searchBar.focus();
  }

  _toggleMenu() {
    this.setState({ menuOpen: !this.state.menuOpen });
    if (this.state.menuOpen) {
      this.props.onMenuCloseCallback();
    }
    else {
      this.props.onMenuOpenCallback();
    }
  }

  _onBackButton() {
    this.props.onBackButtonCallback();
  }

  _showAutocompleteResultsView() {
    this.setState({ resultTypeShowing: AUTOCOMPLETE_RESULTS });
  }

  _showSearchResultsView() {
    this.setState({ resultTypeShowing: SEARCH_RESULTS });
  }

  _hideResultsView() {
    this.setState({ resultTypeShowing: null });
  }

  _onPoiAutocompleteOptionSelected(result) {
    this.props.searchViewModel.performTextSearch(result.title, {
      skipYelpSearch: true,
      skipLocationSearch: true
    });
    this.props.onPoiAutocompleteOptionSelectedCallback(result);
  }

  // When selecting location autocomplete suggestions or location search results
  _onLocationOptionSelected(option) {
    this._onLocationSelect(option.location);
    this.props.searchViewModel.setSearchParams(searchTypes.TEXT, option.title, {});
    this.setState({ searchString: option.title });
  }

  _onSearchResultSelected(result) {
    this.props.onSearchResultSelectedCallback(result);
  }

  _onTagSelect(tagObject) {
    this.props.searchViewModel.performTagSearch(tagObject.searchTag, tagObject);
  }

  // When selecting Locations from the old 'Location' menu options
  _onLocationSelect(location) {
    this._hideResultsView();
    this.props.mapController.goToLatLng(location.latLng, location);
    this.props.searchViewModel.clear();
  }

  _onInputChange(event) {
    const { searchViewModel, onInputChangeCallback } = this.props;
    const input = event.target.value;

    if (!input) {
      this.clear();
      return;
    }

    searchViewModel.performAutocomplete(input);
    this.setState({ searchString: input });
    onInputChangeCallback(input);
  }

  _onClearClicked() {
    this.clear();
  }

  _processSearch() {
    this.props.searchViewModel.performSearch();
  }

  _getMenu() {
    const { config, mapController } = this.props;
    const { menuOpen, isIndoors } = this.state;

    if (config.get("backButtonEnabled")) {
      return <button className="back-button eegeo-ripple-button" onClick={() => { this._onBackButton(); }}/>;
    }

    return (
      <MenuContainer config={config}
        mapController={mapController}
        menuOpen={menuOpen}
        isIndoors={isIndoors}
        toggleMenu={this._toggleMenu}
        closeMenu={this.closeMenu}
        onTagOptionSelected={this._onTagSelect}
        onLocationOptionSelected={this._onLocationSelect}/>
    );
  }

  render() {
    const { searchString,
      resultTypeShowing,
      menuOpen,
      suggestions,
      searchResults,
      contentVisible,
      animated } = this.state;

    const { config, searchViewModel } = this.props;

    const className = "eegeo-searchbar-container" + (this._showMenuButton ? "" : " no-menu") + (contentVisible ? " visible" : " hidden") + (animated ? " animated" : "");
    const menu = this._showMenuButton ? this._getMenu() : null;

    const shouldShowSpinner = searchViewModel.expectingResultsOrSuggestions();

    const placeholder = config.get("placeholderText") || "Search WRLD Maps";

    const configPoiSourceName = config.get("poiSourceName");
    const poiSourceName = ((configPoiSourceName === undefined) || (configPoiSourceName === null)) ? "WRLD" : configPoiSourceName;
    const locationSourceName = config.get("locationSourceName") || "Geographical";
    const showTagsInResults = config.has("showTagsInResults") ? config.get("showTagsInResults") : true;
    const readableNamesForEndpoints = config.has("readableNamesForEndpoints") ? config.get("readableNamesForEndpoints") : {};
    const customSearchResultContentView = config.has("customSearchResultContentView") ? config.get("customSearchResultContentView") : null;

    return (
      <div className={className}>
        <SearchBar
          searchString={searchString}
          placeholder={placeholder}
          resultTypeShowing={resultTypeShowing}
          menuOpen={menuOpen}
          showAutocompleteResults={this._showAutocompleteResultsView}
          hideResults={this._hideResultsView}
          processSearch={this._processSearch}
          onInputChange={this._onInputChange}
          onClearClick={this._onClearClicked}
          shouldShowSpinner={shouldShowSpinner}
          ref={(node) => { this._searchBar = node; }}
        />
        <ResultsContainer
          resultTypeShowing={resultTypeShowing}
          menuOpen={menuOpen}
          poiSourceName={poiSourceName}
          locationSourceName={locationSourceName}
          readableNamesForEndpoints={readableNamesForEndpoints}
          suggestions={suggestions}
          searchResults={searchResults}
          onPoiAutocompleteOptionSelected={this._onPoiAutocompleteOptionSelected}
          onLocationOptionSelected={this._onLocationOptionSelected}
          onTagAutocompleteOptionSelected={this._onTagSelect}
          onSearchResultSelected={this._onSearchResultSelected}
          onShowMoreSearchResults={this._showMoreSearchResults}
          showTagsInResults={showTagsInResults}
          receivedAllResults={!shouldShowSpinner}
          customSearchResultContentView={customSearchResultContentView}
        />
        {menu}
      </div>
    );
  }
}
