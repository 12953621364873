import React from "react";

import tagService from "./../../../../../common/template/services/TagService";

export default class SearchResultLayout extends React.Component {

  _renderContent() {
    const { context, render, renderCustom } = this.props;
    const customView = (typeof renderCustom === "function") ? renderCustom(context) : null;
    let content = null;
    if (typeof customView === "string") {
      return <div className="details" dangerouslySetInnerHTML={{__html: customView}}/>;
    }
    else if (customView !== null && React.isValidElement(customView)) {
      content = customView;
    }
    else {
      content = render();
    }

    return (
      <div className="details">
        {content}
      </div>
    );
  }

  render() {
    const { context, onSelect } = this.props;
    const { result } = context;

    result.iconKey = result.iconKey || tagService.getDefaultIconKey();
    const iconUrl = tagService.getIconUrlForKey(result.iconKey);
    const iconStyle = {
      backgroundImage: "url(" + iconUrl + ")"
    };

    return (
      <div className="search-result-row" onClick={onSelect}>
        <div className="icon" style={iconStyle}/>
        {this._renderContent()}
      </div>
    );
  }
}