
export default class Config {

  constructor(config) {
    this._config = {
      apiKey: null,
      geoNamesUsername: null,
      eegeoSearchServiceUrl: "https://poi.wrld3d.com/v1.1/",
      menuGroupConfigs: [],
      outdoorSearchMenuItems: [],
      indoorSearchMenuItems: [],
      locationJumps: [],
      overrideIndoorSearchMenuItems: false,
      menuTitle: null,
      placeholderText: null,
      showTagsInResults: true,
      samlConfiguration: null,
      yelpCategoriesToIgnore: [
        "adultentertainment",
        "adult"
      ],
      customSearchResultContentView: undefined,
      latLngSearchEnabled: false,
      mortonKeySearchEnabled: false
    };

    if (config) {
      this.assign(config);
    }
  }

  assign(config) {
    Object.keys(config).forEach((param) => {
      this._config[param] = config[param];
    });
  }

  get(key) {
    return this.has(key) ? this._config[key] : null;
  }

  has(key) {
    return key in this._config;
  }
}
