import YelpPoiService from "./external/YelpPoiService";
import { getYelpCategoriesFromEegeoTag, getReadableYelpCategories, getIconKeyFromYelpCategories } from "./../helpers/YelpCategoryConversions";

const MAX_YELP_RESULTS = 20;

export default class EegeoYelpSearchService {

  constructor(map, poiServiceApiKey, eegeoPoiServiceUrl, customPoiViewConfig, categoriesToIgnore) {
    this._map = map;
    this.initialize(poiServiceApiKey, eegeoPoiServiceUrl, customPoiViewConfig, categoriesToIgnore);
    this._enabled = true;
  }

  initialize(poiServiceApiKey, eegeoPoiServiceUrl, customPoiViewConfig, categoriesToIgnore) {
    this._customPoiViewConfig = customPoiViewConfig;
    this._categoriesToIgnore = categoriesToIgnore;
    this._yelpSearchService = new YelpPoiService();
    this._yelpSearchService.initialize(poiServiceApiKey, eegeoPoiServiceUrl);
  }

  setEnabled(enabled) {
    this._enabled = enabled;
  }

  isEnabled() {
    return this._enabled && this._yelpSearchService.isInitialized();
  }

  fetchAllNearbyYelpPois(callback) {
    const fetchFunc = (latLng) => {
      return this._yelpSearchService.fetchAllNearby(latLng, MAX_YELP_RESULTS, this._getSearchRadius());
    };
    this._fetchYelpPois(fetchFunc, callback);
  }

  fetchNearbyYelpPoisByTerm(term, callback) {
    term = term.toLowerCase();
    if (term.trim().length === 0) {
      callback([]);
      return;
    }
    const fetchFunc = (latLng) => {
      return this._yelpSearchService.fetchNearbyByTerm(latLng, term, MAX_YELP_RESULTS, this._getSearchRadius());
    };
    this._fetchYelpPois(fetchFunc, callback);
  }

  fetchNearbyYelpPoisByTag(tag, callback) {
    const categories = getYelpCategoriesFromEegeoTag(tag);
    if (categories === null) return;
    this.fetchNearbyYelpPoisByYelpCategories(categories, callback);
  }

  fetchNearbyYelpPoisByYelpCategories(categories, callback) {
    const fetchFunc = (latLng) => {
      return this._yelpSearchService.fetchNearbyByCategories(latLng, categories, MAX_YELP_RESULTS, this._getSearchRadius());
    };
    this._fetchYelpPois(fetchFunc, callback);
  }

  _fetchYelpPois(fetchFunc, callback) {
    if (!this.isEnabled()) {
      callback([]);
      return;
    }
    const latLng = this._map.getCenter();

    fetchFunc(latLng)
      .then(response => response.json())
      .then(result => {
        const pois = this._formatYelpResults(result.businesses);
        callback(pois);
      })
      .catch(() => { callback([]); });
  }

  _formatYelpResults(results) {
    const pois = [];
    results.forEach(result => {
      if (result.location === undefined || result.coordinates === undefined || result.coordinates === null) return;

      const ignoreResult = result.categories.some(category => {
        return (this._categoriesToIgnore.indexOf(category.alias) > -1);
      });

      if (ignoreResult) return;

      const userData = {};

      if (this._customPoiViewConfig && this._customPoiViewConfig.customPoiViewUrl && this._customPoiViewConfig.customPoiViewHeight) {
        Object.assign(userData, {
          custom_view: this._customPoiViewConfig.customPoiViewUrl,
          custom_view_height: this._customPoiViewConfig.customPoiViewHeight
        });
        Object.assign(userData, result);
      }
      else {
        Object.assign(userData, {
          description: "",
          image_url: result.image_url,
          phone: result.display_phone,
          web: result.url,
          address: result.location.display_address.join(", "),
          rating: result.rating,
          review_count: result.review_count,
          rating_img_url: ""
        });
      }

      // If yelp doesn't know an exact location, latitude and longitude will be null. Ignore these results since we cannot place a marker for them.
      if (result.coordinates.latitude === null || result.coordinates.longitude === null) {
        return;
      }

      pois.push({
        source: "yelp",
        sourceId: result.id,
        title: result.name,
        subtitle: result.location.display_address.join(", "),
        iconKey: getIconKeyFromYelpCategories(result.categories),
        tags: getReadableYelpCategories(result.categories),
        location: {
          latLng: L.latLng(result.coordinates.latitude, result.coordinates.longitude),
          isIndoor: false
        },
        data: {
          user_data: userData
        }
      });
    });
    return pois;
  }

  _getSearchRadius() {
    const min = 50;
    const max = 40000;
    const projectionMatrix = this._map.rendering.getCameraProjectionMatrix();
    if (!projectionMatrix) return max;
    const fov = Math.atan(1 / projectionMatrix[5]) * 2;
    const radius = this._map.getCameraDistanceToInterest() * Math.tan(fov);
    return radius <= min ? min : radius >= max ? max : radius;
  }

  fetchAllNearbyYelpPoisWithRadius(radius, callback) {
    const fetchFunc = (latLng) => {
      return this._yelpSearchService.fetchAllNearby(latLng, MAX_YELP_RESULTS, radius);
    };
    this._fetchYelpPois(fetchFunc, callback);
  }

  fetchNearbyYelpPoisByTermWithRadius(term, radius, callback) {
    term = term.toLowerCase();
    if (term.trim().length === 0) {
      callback([]);
      return;
    }
    const fetchFunc = (latLng) => {
      return this._yelpSearchService.fetchNearbyByTerm(latLng, term, MAX_YELP_RESULTS, radius);
    };
    this._fetchYelpPois(fetchFunc, callback);
  }

  fetchNearbyYelpPoisByTagWithRadius(tag, radius, callback) {
    const categories = getYelpCategoriesFromEegeoTag(tag);
    if (categories === null) {
      return;
    }
    this.fetchNearbyYelpPoisByYelpCategoriesWithRadius(categories, radius, callback);
  }

  fetchNearbyYelpPoisByYelpCategoriesWithRadius(categories, radius, callback) {
    const fetchFunc = (latLng) => {
      return this._yelpSearchService.fetchNearbyByCategories(latLng, categories, MAX_YELP_RESULTS, radius);
    };
    this._fetchYelpPois(fetchFunc, callback);
  }
}
