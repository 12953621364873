/* eslint-disable max-lines */
import Evented from "./../../common/js/Evented";

import latLngValidator from "./../../common/template/helpers/LatLngValidator";
import mortonKeyValidator from "./../../common/template/helpers/MortonKeyValidator";

import SearchService from "./services/SearchService";
import resultIdGenerator from "./helpers/ResultIdGenerator";
import marshalEndpoint from "./helpers/MarshalEndpoint";
import SearchQueryBuilder from "./search/SearchQueryBuilder";
import { searchTypes } from "./search/constants/SearchTypes";

export default class SearchViewModel extends Evented {

  constructor(mapController) {
    super();

    this._mapController = mapController;
    this._searchService = new SearchService(mapController.getMap());
    this._searchQueryBuilder = new SearchQueryBuilder();
    this._currentSearchQuery = null;

    this._autocompleteSuggestions = this._getEmptyAutocompleteSuggestions();
    this._endpoints = []; // TODO: move into search service
    this._searchResults = this._getEmptySearchResults();

    this._performGeonamesSearchIndoors = true;
    this._includeTagsInAutocomplete = true;

    this._searchType = searchTypes.NONE;
    this._searchTerm = "";
    this._searchOptions = {};

    // TODO: the request should tell us what to expect.
    this._expectingPlacesAutocompleteSuggestions = false;
    this._expectingLocationsAutocompleteSuggestions = false;
    this._expectingTagsAutocompleteSuggestions = false;

    this._expectingPlacesSearchResults = false;
    this._expectingLocationsSearchResults = false;
    this._expectingYelpSearchResults = false;

    this._onAutocompleteSuggestionsQueryDispached = this._onAutocompleteSuggestionsQueryDispached.bind(this);
    this._onAutocompleteSuggestionsReceived = this._onAutocompleteSuggestionsReceived.bind(this);
    this._onSearchQueryDispached = this._onSearchQueryDispached.bind(this);
    this._onSearchQueryResultsReceived = this._onSearchQueryResultsReceived.bind(this);

    // Refreshing Searches
    this._onMapPanRefreshResultTimeout = null;
    this._panResultRefreshFrequency = 2000;
    this._fetchCurrentQuery = this._fetchCurrentQuery.bind(this);
    this._startPanResultRefresh = this._startPanResultRefresh.bind(this);
    this._endPanResultRefresh = this._endPanResultRefresh.bind(this);
  }

  init(config) {
    this._searchService.init(config);
    this._searchQueryBuilder.init(config);

    let endpoints = config.get("poiServiceEndpoints") || [];
    if (endpoints.length === 0) endpoints = ["search"];
    this._endpoints = endpoints.map(marshalEndpoint);

    this._mortonKeySearchEnabled = config.has("mortonKeySearchEnabled") ? config.get("mortonKeySearchEnabled") : false;
    this._latLngSearchEnabled = config.has("latLngSearchEnabled") ? config.get("latLngSearchEnabled") : false;
    this._performGeonamesSearchIndoors = config.has("performGeonamesSearchIndoors") ? config.get("performGeonamesSearchIndoors") : true;
    this._includeTagsInAutocomplete = config.has("includeTagsInAutocomplete") ? config.get("includeTagsInAutocomplete") : true;
  }

  getCurrentSearchQuery() {
    return this._currentSearchQuery ? this._currentSearchQuery.get() : null;
  }

  clear() {
    this._clearAutocompleteSuggestions();
    this._clearSearchResults();
    this.setSearchParams(searchTypes.NONE, "", {});
  }

  _getEmptyAutocompleteSuggestions() {
    return {
      places: [],
      locations: [],
      tags: []
    };
  }

  _getEmptySearchResults() {
    const emptyPlacesResults = { tag: {} };
    this._endpoints.forEach((endpoint) => {
      emptyPlacesResults[endpoint.servicePath] = {};
    });
    return {
      places: emptyPlacesResults,
      yelp: {},
      locations: []
    };
  }

  _hasSearchResults() {
    const hasPlacesResults = Object.keys(this._searchResults.places).some((endpointServicePath) => {
      const resultsForEndpoint = this._searchResults.places[endpointServicePath];
      return Object.keys(resultsForEndpoint).length > 0;
    });
    const hasYelpResults = Object.keys(this._searchResults.yelp).length;
    const hasLocationsResults = this._searchResults.locations.length;
    return hasPlacesResults || hasYelpResults || hasLocationsResults;
  }

  expectingResultsOrSuggestions() {
    return this._expectingSearchResults() || this._expectingAutocompleSuggestions();
  }

  performAutocomplete(searchString) {
    let searchType = searchTypes.TEXT;
    const MIN_MORTON_KEY_LENGTH = 11;
    if (this._mortonKeySearchEnabled && mortonKeyValidator.isValid(searchString) && searchString.trim().length >= MIN_MORTON_KEY_LENGTH) {
      searchType = searchTypes.MORTON_KEY;
    }
    else if (this._latLngSearchEnabled && latLngValidator.isValid(searchString)) {
      searchType = searchTypes.LAT_LNG;
    }
    this.setSearchParams(searchType, searchString, {});
    this._fetchAutocompleteSuggestions(searchString);
  }

  _expectingSearchResults() {
    return this._expectingPlacesSearchResults
        || this._expectingLocationsSearchResults
        || this._expectingYelpSearchResults;
  }

  _expectingAutocompleSuggestions() {
    return this._expectingPlacesAutocompleteSuggestions
        || this._expectingLocationsAutocompleteSuggestions
        || this._expectingTagsAutocompleteSuggestions;
  }

  _fetchAutocompleteSuggestions(searchString) {
    this._clearAutocompleteSuggestions();

    const isIndoors = this._mapController.isIndoors();
    const options = {
      latLng: this._mapController.getCenter(),
      includePlaces: true,
      includeLocations: (this._performGeonamesSearchIndoors || !isIndoors),
      includeTags: this._includeTagsInAutocomplete
    };

    this._searchService.fetchAutocompleteSuggestions(searchString, options, this._onAutocompleteSuggestionsQueryDispached, this._onAutocompleteSuggestionsReceived);
  }

  _onAutocompleteSuggestionsQueryDispached(expecting) {
    this._expectingPlacesAutocompleteSuggestions = expecting.includePlaces;
    this._expectingLocationsAutocompleteSuggestions = expecting.includeLocations;
    this._expectingTagsAutocompleteSuggestions = expecting.includeTags;
  }

  _clearAutocompleteSuggestions() {
    this._expectingPlacesAutocompleteSuggestions = false;
    this._expectingLocationsAutocompleteSuggestions = false;
    this._expectingTagsAutocompleteSuggestions = false;
    this._autocompleteSuggestions = this._getEmptyAutocompleteSuggestions();
    this.fire("autocompletesuggestionscleared", { suggestions: this._autocompleteSuggestions });
  }

  _onAutocompleteSuggestionsReceived(result) {
    if (result.type === "places") {
      this._autocompleteSuggestions.places = result.suggestions;
      this._expectingPlacesAutocompleteSuggestions = false;
    }
    else if (result.type === "locations") {
      this._autocompleteSuggestions.locations = result.suggestions;
      this._expectingLocationsAutocompleteSuggestions = false;
    }
    else if (result.type === "tags") {
      this._autocompleteSuggestions.tags = result.suggestions;
      this._expectingTagsAutocompleteSuggestions = false;
    }
    this.fire("autocompletesuggestionsupdated", { suggestions: this._autocompleteSuggestions });
  }

  isCurrentQuery(searchQuery) {
    return this._currentSearchQuery !== null && this._currentSearchQuery.getId() === searchQuery.getId();
  }

  cancelCurrentQuery() {
    this._currentSearchQuery = null;
    this._expectingPlacesSearchResults = false;
    this._expectingLocationsSearchResults = false;
    this._expectingYelpSearchResults = false;
    this._unregisterSearchRefreshCallbacks();
  }

  setSearchParams(type, term, options) {
    this._searchType = type;
    this._searchTerm = term;
    this._searchOptions = (options === undefined || options === null) ? {} : options;
  }

  performTextSearch(searchTerm, options) {
    this.setSearchParams(searchTypes.TEXT, searchTerm, options);
    this.performSearch();
  }

  performTagSearch(searchTag, options) {
    this.setSearchParams(searchTypes.TAG, searchTag, options);
    this.performSearch();
  }

  performSearch() {
    this._clearSearchResults();
    let searchQuery = null;
    switch (this._searchType) {
      case searchTypes.TEXT:
        searchQuery = this._searchQueryBuilder.buildTextSearchQuery(this._searchTerm, this._endpoints, this._searchOptions);
        break;
      case searchTypes.TAG:
        searchQuery = this._searchQueryBuilder.buildTagSearchQuery(this._searchTerm, this._searchOptions);
        break;
      case searchTypes.MORTON_KEY:
        this._mapController.goToMortonKey(this._searchTerm);
        return;
      case searchTypes.LAT_LNG:
        this._mapController.goToLatLng(this._searchTerm);
        return;
      default: return;
    }
    this._performSearch(searchQuery);
  }

  _performSearch(searchQuery) {
    this._currentSearchQuery = searchQuery;

    if (this._currentSearchQuery.shouldRefreshOnMapMove()) {
      this._registerSearchRefreshCallbacks();
    } else {
      // Unregister callbacks if the search shouldn't be refreshed [MPLY-11508]:
      // e.g. search performed through right click menu shouldn't update when the camera is moved.
      this._unregisterSearchRefreshCallbacks();
    }

    this.fire("search", { searchQuery: this._currentSearchQuery });
    this._fetchCurrentQuery();
  }

  _fetchCurrentQuery() {
    const isIndoors = this._mapController.isIndoors();
    const options = {
      includePlaces: true,
      includeLocations: (this._performGeonamesSearchIndoors || !isIndoors),
      includeYelp: !isIndoors
    };
    this._searchService.dispatchQuery(this._currentSearchQuery, options, this._onSearchQueryDispached, this._onSearchQueryResultsReceived);
  }

  _clearSearchResults() {
    this.cancelCurrentQuery();
    if (this._hasSearchResults()) {
      this._searchResults = this._getEmptySearchResults();
      this.fire("searchresultscleared", { searchResults: this._searchResults });
    }
  }

  // Clears results from sources we no longer care about if a search query has been refreshed.
  _clearRedundantResults() {
    const emptyResults = this._getEmptySearchResults();
    if (!this._expectingPlacesSearchResults) {
      this._searchResults.places = emptyResults.places;
    }
    if (!this._expectingLocationsSearchResults) {
      this._searchResults.locations = emptyResults.locations;
    }
    if (!this._expectingYelpSearchResults) {
      this._searchResults.yelp = emptyResults.yelp;
    }
    this.fire("searchresultsupdated", { searchResults: this._searchResults });
  }

  _onSearchQueryDispached(expecting) {
    this._expectingPlacesSearchResults = expecting.expectPlaces;
    this._expectingLocationsSearchResults = expecting.expectLocations;
    this._expectingYelpSearchResults = expecting.expectYelp;
    this._clearRedundantResults();
  }

  _onSearchQueryResultsReceived(result) {
    if (!this.isCurrentQuery(result.searchQuery)) return;
    if (result.type === "places") {
      const stampedResults = this._stampResultsWithIds(result.searchResults);
      if (result.servicePath) {
        this._searchResults.places[result.servicePath] = stampedResults;
      }
      this._expectingPlacesSearchResults = false;
    }
    else if (result.type === "locations") {
      this._searchResults.locations = result.searchResults;
      this._expectingLocationsSearchResults = false;
    }
    else if (result.type === "yelp") {
      const stampedResults = this._stampResultsWithIds(result.searchResults);
      this._searchResults.yelp = stampedResults;
      this._expectingYelpSearchResults = false;
    }
    this.fire("searchresultsupdated", { searchResults: this._searchResults });
  }

  _stampResultsWithIds(results) {
    const stampedResults = {};
    results.forEach((result) => {
      const resultId = resultIdGenerator.getNextId();
      result["resultId"] = resultId;
      stampedResults[resultId] = result;
    });
    return stampedResults;
  }


  /*****************Refreshing Searches */


  _startPanResultRefresh() {
    this._onMapPanRefreshResultTimeout = setTimeout(() => {
      this._fetchCurrentQuery();
      this._startPanResultRefresh();
    }, this._panResultRefreshFrequency);
  }

  _endPanResultRefresh() {
    clearTimeout(this._onMapPanRefreshResultTimeout);
    this._fetchCurrentQuery();
  }

  _registerSearchRefreshCallbacks() {
    this._mapController.registerIndoorsCallback("indoormapenter", this._fetchCurrentQuery);
    this._mapController.registerIndoorsCallback("indoormapexit", this._fetchCurrentQuery);
    this._mapController.registerIndoorsCallback("indoormapfloorchange", this._fetchCurrentQuery);
    this._mapController.registerCallback("panstart", this._startPanResultRefresh);
    this._mapController.registerCallback("panend", this._endPanResultRefresh);
    this._mapController.registerCallback("zoomend", this._fetchCurrentQuery);
  }

  _unregisterSearchRefreshCallbacks() {
    this._mapController.unregisterIndoorsCallback("indoormapenter", this._fetchCurrentQuery);
    this._mapController.unregisterIndoorsCallback("indoormapexit", this._fetchCurrentQuery);
    this._mapController.unregisterIndoorsCallback("indoormapfloorchange", this._fetchCurrentQuery);
    this._mapController.unregisterCallback("panstart", this._startPanResultRefresh);
    this._mapController.unregisterCallback("panend", this._endPanResultRefresh);
    this._mapController.unregisterCallback("zoomend", this._fetchCurrentQuery);
    clearTimeout(this._onMapPanRefreshResultTimeout);
  }

}
