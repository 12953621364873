import tagService from "./../../../common/template/services/TagService";

import GeoNamesLocationService from "./external/GeoNamesLocationService";

const MAX_OPTIONS = 5;
const REQUEST_DELAY = 250;

export default class EegeoLocationSearchService {

  constructor(geoNameUsername, geoNameToken, restrictByCountry, countryCodes, maxLocationResults) {
    this._locationSearchService = new GeoNamesLocationService();
    this._locationSearchService.initialize(geoNameUsername, geoNameToken, restrictByCountry, countryCodes);
    this._maxLocationResults = maxLocationResults;

    this._autocompleteRequestCount = 0;
    this._autocompleteRequestTimeout = null;
  }

  isEnabled() {
    return this._locationSearchService.isInitialized();
  }

  fetchNearbyLocationsByTerm(latLng, term, callback) {
    term = term.toLowerCase();
    const fetchFunc = () => {
      return this._locationSearchService.fetchNearbyByTerm(latLng, term, this._maxLocationResults);
    };

    this._fetchLocations(fetchFunc)
      .then((locations) => { callback(locations); })
      .catch(() => { callback([]); });
  }

  fetchAutocompleteOptions(latLng, term, callback) {
    term = term.toLowerCase();
    this._cancelCurrentAutocompleteRequest();

    if (term.trim().length === 0) {
      this._bumpAutocompleteRequestCount();
      callback([]);
      return;
    }

    const fetchFunc = () => {
      return this._locationSearchService.fetchAutocompleteResults(term);
    };

    this._autocompleteRequestTimeout = setTimeout(() => {
      const requestNum = this._bumpAutocompleteRequestCount();
      this._fetchLocations(fetchFunc)
        .then((locations) => {
          locations.length = Math.min(MAX_OPTIONS, locations.length);
          callback(locations);
        })
        .catch(() => {
          if (this._isCurrentAutocompleteRequest(requestNum)) {
            callback([]);
          }
        });
    }, REQUEST_DELAY);
  }

  _cancelCurrentAutocompleteRequest() {
    clearTimeout(this._autocompleteRequestTimeout);
  }

  _bumpAutocompleteRequestCount() {
    return ++this._autocompleteRequestCount;
  }

  _isCurrentAutocompleteRequest(request) {
    return this._autocompleteRequestCount === request;
  }

  _fetchLocations(fetchFunc) {
    return new Promise((resolve, reject) => {
      if (!this.isEnabled()) {
        resolve([]);
        return;
      }

      fetchFunc()
        .then(results => {
          const locations = results.map(result => ({
            title: result.name,
            subtitle: result.subtitle,
            location: {
              latLng: result.latLng
            },
            iconKey: tagService.getDefaultIconKey()
          }));
          resolve(locations);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

}
