import Evented from "./../../common/js/Evented";

import React from "react";
import { render } from "react-dom";

import Config from "./Config";
import MapController from "./MapController";

import SearchViewModel from "./SearchViewModel";

import SearchbarContainer from "./searchbar/view/containers/SearchbarContainer.jsx";

class WrldSearchbar extends Evented {

  constructor(id, wrldMap, config) {
    super();
    this._id = id;
    this._reactSearchbar = null;
    this._mapController = new MapController(wrldMap);

    this._config = new Config(config);
    this._searchViewModel = new SearchViewModel(this._mapController);

    this.assignConfig(config);
  }

  assignConfig(config) {
    if (config) {
      this._config.assign(config);
    }
    this._searchViewModel.init(this._config);
    this._render();
  }

  show(animated) {
    this._reactSearchbar.show(animated);
  }

  hide(animated) {
    this._reactSearchbar.hide(animated);
  }

  focus() {
    this._reactSearchbar.focus();
  }

  openMenu() {
    this._reactSearchbar.openMenu();
  }

  closeMenu() {
    this._reactSearchbar.closeMenu();
  }

  clear() {
    this._reactSearchbar.clear();
  }

  performTextSearch(searchTerm, options) {
    this._searchViewModel.performTextSearch(searchTerm, options);
  }

  performTagSearch(searchTag, options) {
    this._searchViewModel.performTagSearch(searchTag, options);
  }

  getActiveSearchQuery() {
    return this._searchViewModel.getCurrentSearchQuery();
  }

  _render() {
    this._reactSearchbar = render(
      <SearchbarContainer
        config={this._config}
        mapController={this._mapController}
        searchViewModel={this._searchViewModel}
        onPoiAutocompleteOptionSelectedCallback={(option) => { this.fire("autocompleteoptionselect", { option: option }); }}
        onSearchResultSelectedCallback={(result) => { this.fire("searchresultselect", { result: result }); }}
        onSearchResultsUpdatedCallback={(results) => { this.fire("searchresultsupdate", { results: results }); }}
        onSearchResultsClearedCallback={() => { this.fire("searchresultsclear"); }}
        onInputChangeCallback={(input) => { this.fire("inputchange", { input: input }); }}
        onSearchCallback={() => { this.fire("search"); }}
        onMenuOpenCallback={() => { this.fire("menuopen"); }}
        onMenuCloseCallback={() => { this.fire("menuclose"); }}
        onBackButtonCallback={() => { this.fire("backbutton"); }}
      />,
      document.getElementById(this._id)
    );
  }
}

const wrldSearchbar = (id, map) => {
  return new WrldSearchbar(id, map);
};

export {
  WrldSearchbar,
  wrldSearchbar,
  WrldSearchbar as EegeoSearchbar,
  wrldSearchbar as eegeoSearchbar
};
