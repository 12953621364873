import React from "react";

import MenuOption from "./MenuOption.jsx";

export default class MenuGroup extends React.Component {

  constructor(props) {
    super(props);
  }

  _getMenuOption() {
    const { menuOptions, initialOptionIndexInGroup, optionIndexExpanded,
      optionIndexToBeExpanded, onExpandCollapseToggle, onCollapseCallback } = this.props;
    let menuOptionsViews = menuOptions.map((option, index) => {
      let optionIndex = initialOptionIndexInGroup + index;
      return (
        <MenuOption
          key={optionIndex}
          optionIndex={optionIndex}
          text={option.text}
          context={option.context}
          callback={option.callback}
          childItems={option.childItems}
          shouldBeExpanded={optionIndexToBeExpanded === optionIndex && optionIndexExpanded === optionIndex}
          onExpandCollapseToggle={onExpandCollapseToggle}
          onCollapsedCallback={onCollapseCallback}
        />
      );
    });

    return (
      <div className="option-list-container" ref={(node) => { this.optionListContainerNode = node; }}>
        {menuOptionsViews}
      </div>
    );
  }

  render() {
    const { title } = this.props;

    let titleDiv = null;
    if ((typeof title === "string") && title !== "") {
      titleDiv = (
        <div className="title-container">
          <div className="title-text">
            {title}
          </div>
        </div>);
    }

    return (
      <div className="menu-option-group">
        {titleDiv}
        {this._getMenuOption()}
      </div>
    );
  }
}
