import React from "react";

export const EegeoSearchResultContent = ({ result, showTags }) => {
  const subtitleElement = result.subtitle ? <div className="subtitle">{result.subtitle}</div> : null;
  const tagsElement = (result.tags && showTags) ?
    <div>
      <div className="tag-icon" />
      <div className="tags">{result.tags.join(", ")}</div>
    </div> : null;

  return (
    <React.Fragment>
      <div className="title">{result.title}</div>
      {subtitleElement}
      {tagsElement}
    </React.Fragment>
  );
};

export default EegeoSearchResultContent;
