/* eslint-disable max-lines */
import EegeoPoiService from "./external/EegeoPoiService";
import tagService from "./../../../common/template/services/TagService";

const MAX_RESULTS = 150;
const MAX_OPTIONS = 5;
const REQUEST_DELAY = 250;

const DEFAULT_TAG_SEARCH_PARAMS = {
  radius: null,
  ignoreInteriorFilter: false
};

export default class EegeoPlacesSearchService {

  constructor(map, apiKey, urlRoot, samlConfiguration, minSearchScore) {
    this._map = map;
    this._apiKey = apiKey;
    this._urlRoot = urlRoot;
    this._poiSearchService = new EegeoPoiService();
    this._poiSearchService.initialize(apiKey, urlRoot, samlConfiguration, minSearchScore);

    this._autocompleteRequestCount = 0;
    this._autocompleteRequestTimeout = null;
  }

  isEnabled() {
    return this._poiSearchService.isInitialized();
  }

  _optionsOrDefault(options) {

    const ignoreInteriorFilter = (options && options.ignoreInteriorFilter);
    const usesRadiusInsideInterior = (options && options.usesRadiusInsideInterior);

    let indoorInfo = null;
    const indoorSearchParamsRequired = !ignoreInteriorFilter && this._map.indoors.isIndoors();
    if (indoorSearchParamsRequired) {
      indoorInfo = {
        id: this._map.indoors.getActiveIndoorMap().getIndoorMapId(),
        floor: this._map.indoors.getFloor().getFloorIndex(),
      };

      indoorInfo = Object.assign(indoorInfo, {currentFloorOnly: usesRadiusInsideInterior});
    }

    const ignoreRadiusParam = usesRadiusInsideInterior && (indoorInfo === null);
    const radius = (options && options.radius !== undefined && options.radius !== null && !ignoreRadiusParam)
      ? options.radius
      : this._getSearchRadius();

    const maxResults = (options && options.maxResults !== undefined && options.maxResults !== null && Number.isInteger(options.maxResults))
      ? options.maxResults
      : MAX_RESULTS;

    options = Object.assign({}, options, {
      indoorInfo: indoorInfo,
      radius: radius,
      maxResults: maxResults
    });

    return options;
  }

  fetchAllNearbyPlaces(latLng, callback, options = {}) {
    const fetchFunc = (options) => {
      return this._poiSearchService.fetchAllNearby(latLng, options.radius, options.maxResults, options.indoorInfo);
    };
    this._fetchPlaces(fetchFunc, options)
      .then((pois) => { callback(pois); })
      .catch(() => { callback([]); });
  }

  fetchNearbyPlacesByTerm(latLng, term, callback, params) {
    term = term.toLowerCase();
    const fetchFunc = (options) => {
      return this._poiSearchService.fetchNearbyByTerm(latLng, term, options.radius, options.maxResults, options.indoorInfo, options.servicePath);
    };
    this._fetchPlaces(fetchFunc, params)
      .then((pois) => { callback(pois); })
      .catch(() => { callback([]); });
  }

  fetchNearbyPlacesByTag(latLng, tag, callback, params) {
    const paramsWithDefaults = Object.assign({}, DEFAULT_TAG_SEARCH_PARAMS, params);

    const fetchFunc = (options) => {
      return this._poiSearchService.fetchNearbyByTag(latLng, tag, options.radius, options.maxResults, options.indoorInfo);
    };

    this._fetchPlaces(fetchFunc, paramsWithDefaults)
      .then((pois) => { callback(pois); })
      .catch(() => { callback([]); });
  }

  fetchAutocompleteOptions(latLng, term, callback) {
    term = term.toLowerCase();
    this._cancelCurrentAutocompleteRequest();

    if (term.trim().length === 0) {
      this._bumpAutocompleteRequestCount();
      callback([]);
      return;
    }

    const fetchFunc = () => {
      return this._poiSearchService.fetchAutocompleteResults(latLng, term, MAX_OPTIONS);
    };

    this._autocompleteRequestTimeout = setTimeout(() => {
      const requestNum = this._bumpAutocompleteRequestCount();
      this._fetchPlaces(fetchFunc)
        .then((places) => {
          const titles = {};
          const uniquePlaces = places.filter(function(place) {
            return (place.title in titles) ? false : (titles[place.title] = true);
          });
          callback(uniquePlaces);
        })
        .catch(() => {
          if (this._isCurrentAutocompleteRequest(requestNum)) {
            callback([]);
          }
        });
    }, REQUEST_DELAY);
  }

  fetchTagOptions(term, callback) {
    term = term.toLowerCase();
    if (!this.isEnabled() || term.trim().length === 0) {
      callback([]);
      return;
    }

    const tagsObject = tagService.getAllTags();
    let options = [];

    Object.keys(tagsObject).forEach(tag => {
      if (tagsObject[tag].name.toLowerCase().indexOf(term) !== -1) {
        options.push({
          name: tagsObject[tag].name,
          searchTag: tag,
          iconKey: tagsObject[tag].iconKey,
          skipYelpSearch: false,
          yelpMapping: null
        });
      }
    });

    options.sort((lhs, rhs) => {
      const lhsIndex = lhs.name.toLowerCase().indexOf(term);
      const rhsIndex = rhs.name.toLowerCase().indexOf(term);

      if (lhsIndex === rhsIndex) {
        if (lhs.name.toLowerCase() < rhs.name.toLowerCase()) return -1;
        if (lhs.name.toLowerCase() > rhs.name.toLowerCase()) return 1;
      }
      return lhsIndex - rhsIndex;
    });

    options.length = Math.min(MAX_OPTIONS, options.length);
    callback(options);
  }

  fetchAllNearbyPlacesWithRadius(latLng, radius, callback) {

    const fetchFunc = (options) => {
      return this._poiSearchService.fetchAllNearby(latLng, options.radius, options.maxResults, options.indoorInfo);
    };
    this._fetchPlaces(fetchFunc, {radius: radius})
      .then((pois) => { callback(pois); })
      .catch(() => { callback([]); });
  }

  fetchNearbyPlacesByTermWithRadius(latLng, radius, term, callback, endpoint) {

    term = term.toLowerCase();
    const fetchFunc = (options) => {
      return this._poiSearchService.fetchNearbyByTerm(latLng, term, options.radius, options.maxResults, options.indoorInfo, endpoint);
    };
    this._fetchPlaces(fetchFunc, {radius: radius})
      .then((pois) => { callback(pois); })
      .catch(() => { callback([]); });
  }

  _cancelCurrentAutocompleteRequest() {
    clearTimeout(this._autocompleteRequestTimeout);
  }

  _bumpAutocompleteRequestCount() {
    return ++this._autocompleteRequestCount;
  }

  _isCurrentAutocompleteRequest(request) {
    return this._autocompleteRequestCount === request;
  }

  _fetchPlaces(fetchFunc, options) {
    return new Promise((resolve, reject) => {
      if (!this.isEnabled()) {
        resolve([]);
        return;
      }

      options = this._optionsOrDefault(options);

      fetchFunc(options)
        .then(response => {
          if (response.status === 200) return response.json();
          return [];
        })
        .then(results => {
          const pois = this._formatResults(results);
          resolve(pois);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  _formatResults(results) {
    const pois = [];
    results.forEach(result => {
      const poi = {
        source: "wrld",
        sourceId: result.id,
        data: Object.assign({}, result)
      };
      poi.title = result.title;
      poi.location = {
        latLng: L.latLng(result.lat, result.lon),
        elevation: result.height_offset,
        isIndoor: result.indoor,
        indoorId: result.indoor_id,
        floorIndex: result.floor_id
      };
      if ("subtitle" in result) {
        poi.subtitle = result.subtitle;
      }
      if ("tags" in result) {
        poi.iconKey = tagService.getIconKeyFromTagsString(result.tags);
        poi.tags = tagService.getHumanReadablesFromTagsString(result.tags);
      }
      else if ("tag" in result) {
        poi.iconKey = tagService.getIconKeyFromTagsString(result.tag);
      }
      pois.push(poi);
    });
    return pois;
  }

  _getSearchRadius() {
    const min = 1000;
    const max = 50000;
    const projectionMatrix = this._map.rendering.getCameraProjectionMatrix();
    if (!projectionMatrix) return max;
    const fov = Math.atan(1 / projectionMatrix[5]) * 2;
    const radius = this._map.getCameraDistanceToInterest() * Math.tan(fov);
    return radius <= min ? min : radius >= max ? max : radius;
  }
}
