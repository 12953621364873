
class SearchQueryIdGenerator {

  constructor() {
    this._nextId = 0;
  }

  getNextId() {
    return this._nextId++;
  }
}

const searchQueryIdGenerator = new SearchQueryIdGenerator;
export default searchQueryIdGenerator;