
import latLngValidator from "./../../common/template/helpers/LatLngValidator";
import mortonKeyValidator from "./../../common/template/helpers/MortonKeyValidator";

export default class MapController {

  constructor(map) {
    this._map = map;
  }

  getMap() {
    return this._map;
  }

  getCenter() {
    return this._map.getCenter();
  }

  isIndoors() {
    return this._map.indoors.isIndoors();
  }

  getIndoorMapId() {
    return this._map.indoors.getActiveIndoorMap().getIndoorMapId();
  }

  getIndoorMapFloorIndex() {
    return this._map.indoors.getFloor().getFloorIndex();
  }

  getIndoorMapSearchTags() {
    return this._map.indoors.getActiveIndoorMap().getSearchTags();
  }

  goToLatLng(latLng, options) {
    options = options || {};
    if (!("headingDegrees" in options) || isNaN(options.headingDegrees)) {
      options.headingDegrees = 0;
    }
    if (!("zoom" in options) || isNaN(options.zoom)) {
      options.zoom = 16;
    }

    if (this._map.indoors.isIndoors()) {
      this._map.indoors.exit();
    }
    if (typeof latLng === "string") {
      latLng = latLngValidator.getLatLngFromString(latLng);
    }
    this._map.setView(latLng, options.zoom, {
      allowInterruption: false,
      durationSeconds: 1.0,
      headingDegrees: options.headingDegrees
    });
  }

  goToMortonKey(key) {
    if (mortonKeyValidator.isValid(key)) {
      this.goToLatLng(this._map.getMortonKeyCenter(key));
    }
  }

  registerCallback(type, callback) {
    this._map.on(type, callback);
  }

  unregisterCallback(type, callback) {
    this._map.off(type, callback);
  }

  registerIndoorsCallback(type, callback) {
    this._map.indoors.on(type, callback);
  }

  unregisterIndoorsCallback(type, callback) {
    this._map.indoors.off(type, callback);
  }
}