import React from "react";

import { SEARCH_RESULTS, AUTOCOMPLETE_RESULTS } from "../../constants/SearchBarConstants";

import TextField from "./TextField.jsx";

export default class SearchBar extends React.Component {

  constructor(props) {
    super(props);

    this._onKeyDown = this._onKeyDown.bind(this);
    this._hideAutocompleteResults = this._hideAutocompleteResults.bind(this);
    this._showAutocompleteResults = this._showAutocompleteResults.bind(this);
  }

  focus() {
    this._textField.focus();
  }

  getInputText() {
    this._textField.getInputText();
  }

  _onKeyDown(event) {
    switch (event.key) {
      case "Enter":
        this.props.processSearch();
        break;
    }
  }

  _hideAutocompleteResults() {
    const { resultTypeShowing, hideResults } = this.props;
    if (resultTypeShowing === AUTOCOMPLETE_RESULTS) {
      const isMouseOverResults = $("#eegeo-results-container:hover").length === 0;
      if (isMouseOverResults) {
        hideResults();
      }
    }
  }

  _showAutocompleteResults() {
    const { resultTypeShowing, showAutocompleteResults } = this.props;
    if (resultTypeShowing !== SEARCH_RESULTS) {
      showAutocompleteResults();
    }
  }

  render() {
    const { resultTypeShowing, searchString, placeholder, processSearch, onInputChange, onClearClick, shouldShowSpinner, menuOpen } = this.props;
    const searchResultsShowing = resultTypeShowing === SEARCH_RESULTS;

    let searchBarClassName = searchResultsShowing ? "showing-search-results" : "";
    searchBarClassName += !searchResultsShowing && searchString.trim().length ? " has-input" : "";
    const searchBarDisabledOverlay = menuOpen ? <div className="disabled-overlay"/> : null;

    return (
      <div id="eegeo-searchbar" className={searchBarClassName}>
        <TextField
          value={searchString}
          placeholder={placeholder}
          onChange={onInputChange}
          onFocus={this._showAutocompleteResults}
          onBlur={this._hideAutocompleteResults}
          onKeyDown={this._onKeyDown}
          onClearClick={onClearClick}
          shouldShowSpinner={shouldShowSpinner}
          ref={(node) => { this._textField = node; }}
        />
        <div id="eegeo-searchbar-search-button-container">
          <button className="eegeo-ripple-button" onClick={processSearch}/>
        </div>
        {searchBarDisabledOverlay}
      </div>
    );
  }
}
