import React from "react";

import SearchResultLayout from "./SearchResultLayout.jsx";
import EegeoSearchResultContent from "./EegeoSearchResultContent.jsx";
import YelpSearchResultContent from "./YelpSearchResultContent.jsx";
import AutocompleteOptionRow from "./AutocompleteOptionRow.jsx";

export default class SearchResultsGroup extends React.Component {

  constructor(props) {
    super(props);

    this._showingState = {
      normal: { numResultsToShow: 5 },
      compressed: { numResultsToShow: 0 },
      expanded: { numResultsToShow: 20 }
    };

    const otherResultGroupShowingMore = props.resultGroupIdShowingMore !== null;
    this.state = {
      showingState: otherResultGroupShowingMore ? this._showingState.compressed : this._showingState.normal,
      numberOfResults: Object.keys(props.results).length,
      indexOfTopResultShowing: 0
    };

    this._updateMaxHeight = this._updateMaxHeight.bind(this);
    this._turnResultPageLeft = this._turnResultPageLeft.bind(this);
    this._turnResultPageRight = this._turnResultPageRight.bind(this);
  }

  componentDidMount() {
    $(window).on("resize", this._updateMaxHeight);
    this._updateMaxHeight();
  }

  componentWillUnmount() {
    $(window).off("resize", this._updateMaxHeight);
    if (this.props.resultGroupIdShowingMore === this.props.id) {
      this.props.onShowMoreSearchResults(null);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let showingState = this._showingState.normal;
    let indexOfTopResultShowing = this.state.indexOfTopResultShowing;
    if (nextProps.resultGroupIdShowingMore === nextProps.id) {
      showingState = this._showingState.expanded;
    }
    else if (nextProps.resultGroupIdShowingMore !== null) {
      showingState = this._showingState.compressed;
      indexOfTopResultShowing = 0;
    }

    if (this.props.results !== nextProps.results) {
      indexOfTopResultShowing = 0;
    }

    this.setState({
      showingState: showingState,
      numberOfResults: Object.keys(nextProps.results).length,
      indexOfTopResultShowing: indexOfTopResultShowing
    });
  }

  componentDidUpdate() {
    this._updateMaxHeight();
  }

  _updateMaxHeight() {
    const { numOtherResultGroups } = this.props;
    const { showingState, numberOfResults } = this.state;
    const searchBarHeight = $("#eegeo-searchbar").outerHeight();
    const searchbarContainerHeight = $("#eegeo-searchbar").parent().parent().height();
    const resultGroupMargin = parseInt($(this.searchResultsGroupNode).css("margin-top"));
    const availableSpace = searchbarContainerHeight - searchBarHeight - (resultGroupMargin * (numOtherResultGroups + 1));

    if (showingState === this._showingState.normal) {
      const resultGroupHeight = this.searchResultsGroupNode.clientHeight;
      const resultsContainerHeight = this.searchResultsGroupNode.parentNode.clientHeight;
      const otherResultGroupHeight = resultsContainerHeight - resultGroupHeight;
      const sharedAvailableSpace = availableSpace / (numOtherResultGroups + 1);
      const searchResultsGroupMaxHeight = Math.max(sharedAvailableSpace, availableSpace - otherResultGroupHeight);
      this.searchResultsGroupNode.style.maxHeight = parseInt(searchResultsGroupMaxHeight) + "px";

      const moreResultsHeight = showingState.numResultsToShow < numberOfResults ? 32 : 0;
      this.searchResultElementsContainerNode.style.maxHeight = parseInt(searchResultsGroupMaxHeight - moreResultsHeight) + "px";
    }
    else if (showingState === this._showingState.expanded) {
      const compressedResultGroupHeight = 32;
      const searchResultsGroupMaxHeight = availableSpace - (numOtherResultGroups * compressedResultGroupHeight);
      this.searchResultsGroupNode.style.maxHeight = parseInt(searchResultsGroupMaxHeight) + "px";

      const paginationFooterHeight = showingState.numResultsToShow < numberOfResults ? 42 : 0;
      this.searchResultElementsContainerNode.style.maxHeight = parseInt(searchResultsGroupMaxHeight - paginationFooterHeight) + "px";
    }
  }

  _turnResultPageLeft() {
    const { showingState, indexOfTopResultShowing } = this.state;
    this.setState({
      indexOfTopResultShowing: Math.max(indexOfTopResultShowing - showingState.numResultsToShow, 0)
    });
  }

  _turnResultPageRight() {
    const { showingState, numberOfResults, indexOfTopResultShowing } = this.state;
    this.setState({
      indexOfTopResultShowing: Math.min(indexOfTopResultShowing + showingState.numResultsToShow, numberOfResults - 1)
    });
  }

  _getResultElements() {
    const { source, results, onResultSelect, showTags, customSearchResultContentView } = this.props;
    const { showingState, numberOfResults, indexOfTopResultShowing } = this.state;

    const resultElements = [];
    for (let resultIndex = indexOfTopResultShowing; resultIndex < indexOfTopResultShowing + showingState.numResultsToShow; ++resultIndex) {
      if (resultIndex >= numberOfResults) { break; }
      const resultKeys = Object.keys(results);
      const resultId = resultKeys[resultIndex];
      const result = results[resultId];

      if (source === "yelp" || source === "wrld") {
        const context = { result, source };
        if (source === "yelp") {
          resultElements.push(
            <SearchResultLayout
              key={resultIndex}
              context={context}
              onSelect={() => onResultSelect(result)}
              renderCustom={customSearchResultContentView}
              render={() => { return <YelpSearchResultContent result={result}/>; }}
            />);
        }
        else if (source === "wrld") {
          resultElements.push(
            <SearchResultLayout
              key={resultIndex}
              context={context}
              onSelect={() => onResultSelect(result)}
              renderCustom={customSearchResultContentView}
              render={() => { return <EegeoSearchResultContent result={result} showTags={showTags}/>; }}
            />);
        }
      }
      else if (source === "location") {
        resultElements.push(<AutocompleteOptionRow key={resultIndex} option={result} onSelect={() => onResultSelect(result)}/>);
      }
    }

    const numResultsToShow = showingState.numResultsToShow;
    resultElements.length = Math.min(numberOfResults, numResultsToShow);

    return resultElements;
  }

  _buildMoreResultsText(numResultsNotShowing, humanReadableSource) {
    if (humanReadableSource) {
      return "See more " + humanReadableSource + " results (" + numResultsNotShowing + ")";
    }
    else {
      return "See more results (" + numResultsNotShowing + ")";
    }
  }

  _getMoreResultsElement() {
    const { id, humanReadableSource, onShowMoreSearchResults } = this.props;
    const { showingState, numberOfResults } = this.state;

    if (showingState !== this._showingState.expanded) {
      const numResultsToShow = showingState.numResultsToShow;
      const numResultShowing = Math.min(numberOfResults, numResultsToShow);
      const numResultsNotShowing = numberOfResults - numResultShowing;

      if (numResultsNotShowing === 0) { return null; }

      const moreResultsText = this._buildMoreResultsText(numResultsNotShowing, humanReadableSource);

      return (
        <div className="more-results" onClick={() => onShowMoreSearchResults(id)}>
          <div className="more-results-icon"></div>
          <div className="more-results-text">{moreResultsText}</div>
        </div>
      );
    }
    else {
      if (showingState.numResultsToShow >= numberOfResults) { return null; }
      const { indexOfTopResultShowing } = this.state;

      const maxResultNumberShowing = Math.min(indexOfTopResultShowing + showingState.numResultsToShow, numberOfResults);
      const resultNumberText = "Results " + (indexOfTopResultShowing + 1) + "-" + maxResultNumberShowing;

      const leftArrowActive = indexOfTopResultShowing > 0;
      const rightArrowActive = maxResultNumberShowing < numberOfResults;
      const leftArrowClassName = "left-arrow-button" + (leftArrowActive ? " active" : " inactive");
      const rightArrowClassName = "right-arrow-button" + (rightArrowActive ? " active" : " inactive");
      const leftArrowOnClick = leftArrowActive ? this._turnResultPageLeft : null;
      const rightArrowOnClick = rightArrowActive ? this._turnResultPageRight : null;

      return (
        <div className="pagination-footer">
          <div className="results-number-text">{resultNumberText}</div>
          <div className={leftArrowClassName} onClick={leftArrowOnClick}/>
          <div className={rightArrowClassName} onClick={rightArrowOnClick}/>
        </div>
      );
    }
  }

  render() {
    return (
      <div className="search-results-group" ref={(node) => { this.searchResultsGroupNode = node; }}>
        <div className="search-result-elements-container" ref={(node) => { this.searchResultElementsContainerNode = node; }}>
          {this._getResultElements()}
        </div>
        {this._getMoreResultsElement()}
      </div>
    );
  }
}