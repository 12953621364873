import PlacesSearchServiceFacade from "./PlacesSearchServiceFacade";
import LocationSearchServiceFacade from "./LocationSearchServiceFacade";

import EegeoPlacesSearchService from "./EegeoPlacesSearchService";
import EegeoLocationSearchService from "./EegeoLocationSearchService";
import EegeoYelpSearchService from "./EegeoYelpSearchService";

const MAX_LOCATION_SEARCH_SERVICE_RESULTS = 10;

export default class SearchService {

  constructor(wrldMap) {
    this._wrldMap = wrldMap;
    this._placesSearchService = new PlacesSearchServiceFacade(null);
    this._locationSearchService = new LocationSearchServiceFacade(null);
    this._yelpSearchService = new EegeoYelpSearchService(wrldMap, null, null, null, null);
  }

  static createDefaultPlacesSearchService(wrldMap, config) {
    const apiKey = config.get("apiKey");
    const urlRoot = config.get("eegeoSearchServiceUrl");
    const samlConfig = config.has("samlConfiguration") ? config.get("samlConfiguration") : null;
    const minSearchScore = config.has("eegeoPlacesMinSearchScore") ? config.get("eegeoPlacesMinSearchScore") : undefined;
    return new EegeoPlacesSearchService(wrldMap, apiKey, urlRoot, samlConfig, minSearchScore);
  }

  static createDefaultLocationSearchService(config) {
    const geoNamesUsername = config.get("geoNamesUsername");
    const geoNamesToken = config.get("geoNamesToken");
    const restrictByCountry = config.has("geoNamesRestricted") ?
      config.get("geoNamesRestricted") :
      false;
    const countryCodes = config.has("geoNamesCountryCodes") ?
      config.get("geoNamesCountryCodes") :
      undefined;
    const maxLocationResults = config.has("defaultLocationSearchServiceMaxResultCount") ?
      config.get("defaultLocationSearchServiceMaxResultCount") :
      MAX_LOCATION_SEARCH_SERVICE_RESULTS;
    return new EegeoLocationSearchService(geoNamesUsername, geoNamesToken, restrictByCountry, countryCodes, maxLocationResults);
  }

  init(config) {
    let placesSearchService = (config.has("placesSearchService")) ? config.get("placesSearchService") : this.constructor.createDefaultPlacesSearchService(this._wrldMap, config);
    this._placesSearchService.initialize(placesSearchService);

    let locationSearchService = (config.has("locationSearchService")) ? config.get("locationSearchService") : this.constructor.createDefaultLocationSearchService(config);
    this._locationSearchService.initialize(locationSearchService);

    const yelpURL = config.get("yelpServiceUrl") || config.get("eegeoSearchServiceUrl");
    const yelpCustomPoiViewConfig = config.get("yelpCustomPoiViewConfig");
    const yelpCategoriesToIgnore = config.get("yelpCategoriesToIgnore");
    this._yelpSearchService.initialize(config.get("apiKey"), yelpURL, yelpCustomPoiViewConfig, yelpCategoriesToIgnore);

    const yelpEnabled = !config.get("skipYelpSearch");
    this._yelpSearchService.setEnabled(yelpEnabled);
  }

  fetchAutocompleteSuggestions(searchString, options, onDispatchedCallback, onReceivedCallback) {
    options = {
      ...options,
      includePlaces: options.includePlaces && this._placesSearchService.isEnabled(),
      includeLocations: options.includeLocations && this._locationSearchService.isEnabled(),
      includeTags: options.includeTags && this._placesSearchService.isEnabled()
    };

    const { latLng, includePlaces, includeLocations, includeTags } = options;

    onDispatchedCallback({
      expectPlaces: options.includePlaces,
      expectLocations: options.includeLocations,
      includeTags: options.includeTags
    });

    if (includePlaces) {
      if (this._placesSearchService.implementsAutocomplete()) {
        this._placesSearchService.fetchAutocompleteOptions(latLng, searchString, (suggestions) => { onReceivedCallback({ type: "places", suggestions }); });
      }
      else {
        onReceivedCallback({ type: "places", suggestions: [] });
      }
    }

    if (includeLocations) {
      if (this._locationSearchService.implementsAutocomplete()) {
        this._locationSearchService.fetchAutocompleteOptions(latLng, searchString, (suggestions) => { onReceivedCallback({ type: "locations", suggestions }); });
      }
      else {
        onReceivedCallback({ type: "locations", suggestions: [] });
      }
    }

    if (includeTags) {
      this._placesSearchService.fetchTagOptions(searchString, (suggestions) => { onReceivedCallback({ type: "tags", suggestions }); });
    }
  }

  dispatchQuery(searchQuery, options, onDispatchedCallback, onReceivedCallback) {
    options = {
      includePlaces: options.includePlaces && this._placesSearchService.isEnabled() && searchQuery.shouldIncludePlacesSearch(),
      includeLocations: options.includeLocations && this._locationSearchService.isEnabled() && searchQuery.shouldIncludeLocationSearch(),
      includeYelp: options.includeYelp && this._yelpSearchService.isEnabled() && searchQuery.shouldIncludeYelpSearch()
    };

    onDispatchedCallback({
      expectPlaces: options.includePlaces,
      expectLocations: options.includeLocations,
      expectYelp: options.includeYelp
    });

    if (searchQuery.isTextSearchQuery()) {
      this._fetchNearbyByTerm(searchQuery, onReceivedCallback, options);
    }
    else if (searchQuery.isTagSearchQuery()) {
      if (searchQuery.hasSearchTerm()) {
        this._fetchNearbyByTag(searchQuery, onReceivedCallback, options);
      }
      else {
        this._fetchAllNearby(searchQuery, onReceivedCallback, options);
      }
    }
  }

  _fetchNearbyByTerm(searchQuery, callback, options) {
    const latLng = this._wrldMap.getCenter();
    const searchTerm = searchQuery.getSearchTerm();

    if (options.includePlaces) {
      searchQuery.getEndpoints().forEach(endpoint => {
        this._placesSearchService.fetchNearbyPlacesByTerm(latLng, searchTerm, (searchResults) => {
          callback({ type: "places", servicePath: endpoint.servicePath, searchResults, searchQuery});
        }, endpoint);
      });
    }

    if (options.includeLocations) {
      this._locationSearchService.fetchNearbyLocationsByTerm(latLng, searchTerm, (searchResults) => { callback({ type: "locations", searchResults, searchQuery }); });
    }

    if (options.includeYelp) {
      this._yelpSearchService.fetchNearbyYelpPoisByTerm(searchTerm, (searchResults) => { callback({ type: "yelp", searchResults, searchQuery }); });
    }
  }

  _fetchAllNearby(searchQuery, callback, options) {
    const latLng = this._wrldMap.getCenter();

    if (options.includePlaces) {
      this._placesSearchService.fetchAllNearbyPlaces(latLng, (searchResults) => {
        callback({ type: "places", servicePath: "tag", searchResults, searchQuery });
      }, searchQuery.getOptions());
    }

    if (options.includeYelp) {
      if (searchQuery.hasRadius()) {
        this._yelpSearchService.fetchAllNearbyYelpPoisWithRadius(searchQuery.getRadius(), (searchResults) => { callback({ type: "yelp", searchResults, searchQuery }); });
      }
      else {
        this._yelpSearchService.fetchAllNearbyYelpPois((searchResults) => { callback({ type: "yelp", searchResults, searchQuery }); });
      }
    }
  }

  _fetchNearbyByTag(searchQuery, callback, options) {
    const latLng = this._wrldMap.getCenter();
    const searchTerm = searchQuery.getSearchTerm();
    if (searchQuery.shouldIncludePlacesSearch()) {
      this._placesSearchService.fetchNearbyPlacesByTag(latLng, searchTerm, (searchResults) => {
        callback({ type: "places", servicePath: "tag", searchResults, searchQuery });
      }, searchQuery.getOptions());
    }

    if (options.includeYelp) {
      if (searchQuery.hasYelpMapping()) {
        this._yelpSearchService.fetchNearbyYelpPoisByYelpCategories(searchQuery.getYelpMapping(), (searchResults) => { callback({ type: "yelp", searchResults, searchQuery }); });
      }
      else if (searchQuery.hasRadius()) {
        this._yelpSearchService.fetchNearbyYelpPoisByTagWithRadius(searchTerm, searchQuery.getRadius(), (searchResults) => { callback({ type: "yelp", searchResults, searchQuery }); });
      }
      else {
        this._yelpSearchService.fetchNearbyYelpPoisByTag(searchTerm, (searchResults) => { callback({ type: "yelp", searchResults, searchQuery }); });
      }
    }
  }
}