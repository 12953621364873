import React from "react";

export const NoResultsRow = () => {
  const whiteFrowningFace = "\u2639";
  return (
    <div className="no-results-row">
      <div>{whiteFrowningFace}</div>
      Sorry, no results found
    </div>
  );
};

export default NoResultsRow;
