export default class YelpPoiService {

  constructor() {
    this._initialized = false;
    this._poiServiceApiKey = null;
    this._eegeoPoiServiceUrl = "https://poi.wrld3d.com/v2/yelp";
  }

  initialize(poiServiceApiKey, eegeoPoiServiceUrl) {
    if (poiServiceApiKey) {
      this._poiServiceApiKey = poiServiceApiKey;
      this._initialized = true;
    }
    else {
      this._poiServiceApiKey = null;
      this._initialized = false;
    }
    if (eegeoPoiServiceUrl)
    {
      const endUrlRoot= eegeoPoiServiceUrl.indexOf("/", 9);
      const urlRoot = eegeoPoiServiceUrl.slice(0, endUrlRoot);
      this._eegeoPoiServiceUrl = urlRoot + "/v2/yelp";
    }
  }

  isInitialized() {
    return this._initialized;
  }

  fetchAllNearby(latLng, maxResults, searchRadius) {
    return fetch(this._eegeoPoiServiceUrl + "?apikey=" + this._poiServiceApiKey + "&lat=" + latLng.lat + "&lon=" + latLng.lng + "&limit=" + maxResults + "&radius=" + searchRadius);
  }

  fetchNearbyByTerm(latLng, term, maxResults, searchRadius) {
    return fetch(this._eegeoPoiServiceUrl + "?apikey=" + this._poiServiceApiKey + "&lat=" + latLng.lat + "&lon=" + latLng.lng + "&limit=" + maxResults + "&term=" + term + "&radius=" + searchRadius);
  }

  fetchNearbyByCategories(latLng, categories, maxResults, searchRadius) {
    return fetch(this._eegeoPoiServiceUrl + "?apikey=" + this._poiServiceApiKey + "&lat=" + latLng.lat + "&lon=" + latLng.lng + "&limit=" + maxResults + "&category_filter=" + categories + "&radius=" + searchRadius);
  }
}
