import React from "react";

import { SEARCH_RESULTS, AUTOCOMPLETE_RESULTS } from "../../constants/SearchBarConstants";

import SearchResultsContainer from "./SearchResultsContainer.jsx";
import AutocompleteOptionsContainer from "./AutocompleteOptionsContainer.jsx";

export const ResultsContainer = ({
  resultTypeShowing,
  resultSourceShowingMore,
  menuOpen,
  poiSourceName,
  locationSourceName,
  readableNamesForEndpoints,
  suggestions,
  searchResults,
  onPoiAutocompleteOptionSelected,
  onLocationOptionSelected,
  onTagAutocompleteOptionSelected,
  onSearchResultSelected,
  onShowMoreSearchResults,
  showTagsInResults,
  receivedAllResults,
  customSearchResultContentView
}) => {
  let autocompleteOptionsContainer = null;
  let searchResultsContainer = null;
  if (resultTypeShowing === AUTOCOMPLETE_RESULTS) {
    autocompleteOptionsContainer = <AutocompleteOptionsContainer
      options={suggestions}
      onPoiOptionSelect={onPoiAutocompleteOptionSelected}
      onLocationOptionSelect={onLocationOptionSelected}
      onTagOptionSelect={onTagAutocompleteOptionSelected}
    />;
  } else if (resultTypeShowing === SEARCH_RESULTS) {
    searchResultsContainer = <SearchResultsContainer
      resultSourceShowingMore={resultSourceShowingMore}
      menuOpen={menuOpen}
      poiSourceName={poiSourceName}
      locationSourceName={locationSourceName}
      readableNamesForEndpoints={readableNamesForEndpoints}
      searchResults={searchResults}
      onResultSelect={onSearchResultSelected}
      onLocationResultSelect={onLocationOptionSelected}
      onShowMoreSearchResults={onShowMoreSearchResults}
      showTagsInResults={showTagsInResults}
      receivedAllResults={receivedAllResults}
      customSearchResultContentView={customSearchResultContentView}
    />;
  }

  return (
    <div id="eegeo-results-container">
      {autocompleteOptionsContainer}
      {searchResultsContainer}
    </div>
  );
};

export default ResultsContainer;
