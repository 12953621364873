
class ResultIdGenerator {

  constructor() {
    this._nextId = 0;
  }

  getNextId() {
    return this._nextId++;
  }
}

const resultIdGenerator = new ResultIdGenerator;
export default resultIdGenerator;