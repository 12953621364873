
import searchQueryIdGenerator from "./SearchQueryIdGenerator";
import { searchTypes } from "./constants/SearchTypes";

const DEFAULT_SEARCH_OPTIONS = {
  radius: null,
  usesRadiusInsideInterior: false,
  skipYelpSearch: false,
  skipPoiSearch: false,
  skipLocationSearch: false,
  refreshSearch: true
};

const DEFAULT_TEXT_SEARCH_OPTIONS = {
};

const DEFAULT_TAG_SEARCH_OPTIONS = {
  yelpMapping: null,
  skipLocationSearch: true,
  ignoreInteriorFilter: false
};

export default class SearchQuery {

  constructor(searchTerm, searchType, options) {
    this._id = searchQueryIdGenerator.getNextId();
    this._searchTerm = searchTerm;
    this._searchType = searchType;
    this._options = this._mergeDefaultOptions(options);
  }

  _mergeDefaultOptions(options) {
    let searchTypeDefaultOptions = {};
    if (this.isTextSearchQuery()) {
      searchTypeDefaultOptions = DEFAULT_TEXT_SEARCH_OPTIONS;
    }
    else if (this.isTagSearchQuery()) {
      searchTypeDefaultOptions = DEFAULT_TAG_SEARCH_OPTIONS;
    }
    return Object.assign({}, DEFAULT_SEARCH_OPTIONS, searchTypeDefaultOptions, options);
  }

  get() {
    return {
      type: this._searchType,
      term: this._searchTerm,
      options: this.getOptions()
    };
  }

  getId() {
    return this._nextId;
  }

  getEndpoints() {
    return this._options.endpoints;
  }

  hasSearchTerm() {
    return (this._searchTerm !== undefined && this._searchTerm !== null && this._searchTerm !== "");
  }

  getSearchTerm() {
    return this._searchTerm;
  }

  getDisplayString() {
    if (this.isTagSearchQuery()) {
      return this._options.displayString;
    }
    return this._searchTerm;
  }

  isTextSearchQuery() {
    return this._searchType === searchTypes.TEXT;
  }

  isTagSearchQuery() {
    return this._searchType === searchTypes.TAG;
  }

  shouldIncludePlacesSearch() {
    return !this._options.skipPoiSearch;
  }

  shouldIncludeYelpSearch() {
    return !this._options.skipYelpSearch;
  }

  shouldIncludeLocationSearch() {
    return !this._options.skipLocationSearch;
  }

  getOptions() {
    // TODO: only return relevant
    return Object.assign({}, this._options);
  }

  getRadius() {
    return this._options.radius;
  }

  hasRadius() {
    const { radius } = this._options;
    return radius !== undefined && radius !== null;
  }

  getYelpMapping() {
    return this._options.yelpMapping;
  }

  hasYelpMapping() {
    const { yelpMapping } = this._options;
    return yelpMapping !== undefined && yelpMapping !== null;
  }

  shouldRefreshOnMapMove() {
    return this._options.refreshSearch;
  }
}