import React from "react";
import tagService from "./../../../../../common/template/services/TagService";

export const AutocompleteOptionRow = ({ option, onSelect }) => {
  option.iconKey = option.iconKey || tagService.getDefaultIconKey();
  const iconUrl = tagService.getIconUrlForKey(option.iconKey);
  const iconStyle = {
    backgroundImage: "url(" + iconUrl + ")"
  };

  const title = option.title || option.name;
  const hasSubtitle = option.subtitle !== undefined;
  const subtitleElement = hasSubtitle ? <div className="subtitle">{option.subtitle}</div> : null;

  return (
    <div className={"autocomplete-option-row" + (hasSubtitle ? " with-subtitle" : "")} onClick={onSelect}>
      <div className="icon" style={iconStyle} />
      <div className="text">
        <div className="title">{title}</div>
        {subtitleElement}
      </div>
    </div>
  );
};

export default AutocompleteOptionRow;
