import React from "react";

export default class YelpSearchResultContent extends React.Component {

  getClassForRating(rating) {
    const decimalRating = rating.toString();
    const classNameSuffix = decimalRating.replace(".5", "-half");
    return "yelp-review-icon yelp-stars-" + classNameSuffix;
  }

  render() {
    const { result } = this.props;

    const subtitleElement = result.subtitle ? <div className="subtitle">{result.subtitle}</div> : null;
    const yelpReviewIconClass = this.getClassForRating(result.data.user_data.rating);
    return (
      <React.Fragment>
        <div className="title">{result.title}</div>
        {subtitleElement}
        <div className="yelp">
          <div className={yelpReviewIconClass}/>
          <div className="review-text">{result.data.user_data.review_count + " reviews on "}</div>
          <div className="yelp-logo"></div>
        </div>
      </React.Fragment>
    );
  }
}