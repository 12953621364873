import React from "react";

import AutocompleteOptionRow from "./AutocompleteOptionRow.jsx";

export default class AutocompleteOptionsGroup extends React.Component {

  constructor(props) {
    super(props);

    this._updateMaxHeight = this._updateMaxHeight.bind(this);
  }

  componentDidMount() {
    $(window).on("resize", this._updateMaxHeight);
    this._updateMaxHeight();
  }

  componentWillUnmount() {
    $(window).off("resize", this._updateMaxHeight);
  }

  componentDidUpdate() {
    this._updateMaxHeight();
  }

  _updateMaxHeight() {
    const { numOtherOptionGroups } = this.props;
    const searchBarHeight = $("#eegeo-searchbar").outerHeight();
    const searchbarContainerHeight = $("#eegeo-searchbar").parent().parent().height();
    if (searchbarContainerHeight <= searchBarHeight) return;
    const optionGroupMargin = parseInt($(this.autocompleteOptionsGroupNode).css("margin-top"));
    const availableSpace = searchbarContainerHeight - searchBarHeight - (optionGroupMargin * (numOtherOptionGroups + 1));

    const optionGroupHeight = this.autocompleteOptionsGroupNode.clientHeight;
    const optionsContainerHeight = this.autocompleteOptionsGroupNode.parentNode.clientHeight;
    const otherOptionsGroupHeight = optionsContainerHeight - optionGroupHeight;
    const sharedAvailableSpace = availableSpace / (numOtherOptionGroups + 1);
    const searchOptionGroupMaxHeight = Math.max(sharedAvailableSpace, availableSpace - otherOptionsGroupHeight);
    this.autocompleteOptionsGroupNode.style.maxHeight = parseInt(searchOptionGroupMaxHeight) + "px";

    const headerHeight = $(this.headerNode).outerHeight();
    this.autocompleteOptionElementsContainerNode.style.maxHeight = parseInt(searchOptionGroupMaxHeight - headerHeight) + "px";
  }

  render() {
    const { options, onOptionSelect } = this.props;

    const optionElements = options.map((option, optionIndex) => {
      return <AutocompleteOptionRow key={optionIndex} option={option} onSelect={onOptionSelect.bind(this, option)}/>;
    });

    return (
      <div className="autocomplete-options-group" ref={(node) => { this.autocompleteOptionsGroupNode = node; }}>
        <div className="autocomplete-option-elements-container" ref={(node) => { this.autocompleteOptionElementsContainerNode = node; }}>
          {optionElements}
        </div>
      </div>
    );
  }
}