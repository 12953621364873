import React from "react";

import SearchResultsGroup from "../components/SearchResultsGroup.jsx";
import NoResultsRow from "../components/NoResultsRow.jsx";

export default class SearchResultsContainer extends React.Component {

  constructor(props) {
    super(props);

    let numResultGroups = 0;

    for (let endpoint in props.searchResults.places) {
      let resultsForEndpoint = props.searchResults.places[endpoint];
      if (Object.keys(resultsForEndpoint).length > 0) numResultGroups++;
    }

    if (Object.keys(props.searchResults.yelp).length > 0) numResultGroups++;
    if (props.searchResults.locations.length > 0) numResultGroups++;

    this.state = {
      numResultGroups: numResultGroups,
      resultGroupIdShowingMore: null
    };

    this._showMoreSearchResults = this._showMoreSearchResults.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let numResultGroups = 0;

    for (let endpoint in nextProps.searchResults.places) {
      let resultsForEndpoint = nextProps.searchResults.places[endpoint];
      if (Object.keys(resultsForEndpoint).length > 0) numResultGroups++;
    }

    if (Object.keys(nextProps.searchResults.yelp).length > 0) numResultGroups++;
    if (nextProps.searchResults.locations.length > 0) numResultGroups++;

    this.setState({ numResultGroups: numResultGroups });
  }

  _showMoreSearchResults(resultGroupId) {
    this.setState({ resultGroupIdShowingMore: resultGroupId });
  }

  _getPlacesSearchResultsGroup(endpoint) {
    const { searchResults, onResultSelect, poiSourceName, readableNamesForEndpoints, showTagsInResults, customSearchResultContentView } = this.props;
    const { resultGroupIdShowingMore, numResultGroups } = this.state;
    const humanReadableSource = endpoint in readableNamesForEndpoints ? readableNamesForEndpoints[endpoint] : poiSourceName;

    if (Object.keys(searchResults.places[endpoint]).length === 0) { return null; }
    return <SearchResultsGroup
      key={endpoint}
      source={"wrld"}
      id={"wrld-"+endpoint}
      humanReadableSource={humanReadableSource}
      numOtherResultGroups={numResultGroups - 1}
      results={searchResults.places[endpoint]}
      onResultSelect={onResultSelect}
      resultGroupIdShowingMore={resultGroupIdShowingMore}
      onShowMoreSearchResults={this._showMoreSearchResults}
      showTags={showTagsInResults}
      customSearchResultContentView={customSearchResultContentView}
    />;
  }

  _getPlacesSearchResultsGroups(){
    let groups = [];

    for (let endpoint in this.props.searchResults.places) {
      const group = this._getPlacesSearchResultsGroup(endpoint);
      if (group) {
        groups.push(group);
      }
    }

    if (groups.length === 0) {
      return null;
    }
    return groups;
  }

  _getYelpResultsGroup() {
    const { searchResults, onResultSelect, customSearchResultContentView } = this.props;
    const { resultGroupIdShowingMore, numResultGroups } = this.state;
    if (Object.keys(searchResults.yelp).length === 0) { return null; }
    return <SearchResultsGroup
      source="yelp"
      id={"yelp"}
      humanReadableSource="yelp"
      numOtherResultGroups={numResultGroups - 1}
      results={searchResults.yelp}
      onResultSelect={onResultSelect}
      resultGroupIdShowingMore={resultGroupIdShowingMore}
      onShowMoreSearchResults={this._showMoreSearchResults}
      customSearchResultContentView={customSearchResultContentView}
    />;
  }

  _getLocationResultsGroup() {
    const { searchResults, onLocationResultSelect, locationSourceName, customSearchResultContentView } = this.props;
    const { resultGroupIdShowingMore, numResultGroups } = this.state;
    if (searchResults.locations.length === 0) { return null; }
    return <SearchResultsGroup
      source="location"
      id={"location"}
      humanReadableSource={locationSourceName}
      numOtherResultGroups={numResultGroups - 1}
      results={searchResults.locations}
      onResultSelect={onLocationResultSelect}
      resultGroupIdShowingMore={resultGroupIdShowingMore}
      onShowMoreSearchResults={this._showMoreSearchResults}
      customSearchResultContentView={customSearchResultContentView}
    />;
  }

  _getResultGroups() {
    const placesSearchResultsGroups = this._getPlacesSearchResultsGroups();
    const yelpResultsGroup = this._getYelpResultsGroup();
    const locationResultsGroup = this._getLocationResultsGroup();

    if (this.props.receivedAllResults && placesSearchResultsGroups === null
        && yelpResultsGroup === null && locationResultsGroup === null) {

      return <NoResultsRow/>;
    }

    return (
      <React.Fragment>
        {placesSearchResultsGroups}
        {yelpResultsGroup}
        {locationResultsGroup}
      </React.Fragment>
    );
  }

  render() {
    return this.props.menuOpen ? null : this._getResultGroups();
  }
}