export default class PlacesSearchServiceFacade {

  constructor(internalSearchService) {
    this.initialize(internalSearchService);
  }

  initialize(internalSearchService) {
    this._internalSearchService = internalSearchService;
  }

  isEnabled() {
    if (this._internalSearchService && typeof this._internalSearchService.isEnabled === "function") {
      return this._internalSearchService.isEnabled();
    }
    return true;
  }

  fetchAllNearbyPlaces(latLng, callback, options) {
    if (this._internalSearchService && typeof this._internalSearchService.fetchAllNearbyPlaces === "function") {
      this._internalSearchService.fetchAllNearbyPlaces(latLng, callback, options);
    }
  }

  fetchNearbyPlacesByTerm(latLng, term, callback, params) {
    if (this._internalSearchService && typeof this._internalSearchService.fetchNearbyPlacesByTerm === "function") {
      // This contract extends the documented method at https://www.wrld3d.com/wrld.js/latest/docs/api/Widgets/WrldSearchbar/#fetchnearbyplacesbytermlatlng-term-callback - the documentation should probably be updated
      this._internalSearchService.fetchNearbyPlacesByTerm(latLng, term, callback, params);
    }
  }

  fetchNearbyPlacesByTag(latLng, tag, callback, params) {
    if (this._internalSearchService && typeof this._internalSearchService.fetchNearbyPlacesByTag === "function") {
      // This contract extends the documented method at https://www.wrld3d.com/wrld.js/latest/docs/api/Widgets/WrldSearchbar/#fetchnearbyplacesbytermlatlng-term-callback - the documentation should probably be updated
      this._internalSearchService.fetchNearbyPlacesByTag(latLng, tag, callback, params);
    }
  }

  fetchAutocompleteOptions(latLng, term, callback) {
    if (this._internalSearchService && typeof this._internalSearchService.fetchAutocompleteOptions === "function") {
      this._internalSearchService.fetchAutocompleteOptions(latLng, term, callback);
    }
  }

  fetchTagOptions(term, callback) {
    if (this._internalSearchService && typeof this._internalSearchService.fetchTagOptions === "function") {
      this._internalSearchService.fetchTagOptions(term, callback);
    }
  }

  implementsAutocomplete() {
    return (this._internalSearchService && typeof this._internalSearchService.fetchAutocompleteOptions === "function");
  }

  fetchAllNearbyPlacesWithRadius(latLng, radius, callback) {
    if (this._internalSearchService && typeof this._internalSearchService.fetchAllNearbyPlaces === "function") {
      this._internalSearchService.fetchAllNearbyPlacesWithRadius(latLng, radius, callback);
    }
  }

  fetchNearbyPlacesByTermWithRadius(latLng, term, radius, callback, endpoint) {
    if (this._internalSearchService && typeof this._internalSearchService.fetchNearbyPlacesByTerm === "function") {
      this._internalSearchService.fetchNearbyPlacesByTermWithRadius(latLng, radius, term, callback, endpoint);
    }
  }
}
