const marshalEndpoint = (endpoint) => {
  const defaultEndpointConfig = {
    servicePath: "",
    ignoreInteriorFilter: false
  };

  if (typeof endpoint === "string")
  {
    return Object.assign(defaultEndpointConfig, {servicePath: endpoint});
  } else {
    return Object.assign(defaultEndpointConfig, endpoint);
  }
};

export default marshalEndpoint;